import * as api from "../../../APICalls/index";

export const FetchingAnnouncementNotification = () => async () => {
  try {
    const { data } = await api.fetchingAnnouncement();
    if (data) {
      return data;
    }
    return data;
  } catch (error) {
    return error;
  }
};

// src/graphql/queries.ts
import { gql } from "@apollo/client";

export const GET_PRODUCTS = gql`
  query GetProducts($masjidId: String!, $type: String!) {
    getProducts(masjidId: $masjidId, type: $type) {
      id
      name
      description
      active
      prices
      images
      createdAt
    }
  }
`;
export const GET_MEMBERS = gql`
  query GetMembers($masjidId: ID!) {
    getMembers(masjidId: $masjidId) {
      _id
      personName
      email
      phoneNumber
      designation
      linkedIn
    }
  }
`;
export const GET_MASJIDS = gql`
  query GetMasjids($limit: Int, $page: Int) {
    getMasjids(limit: $limit, page: $page) {
      subscribers
      followers
      _id
      masjidName
      masjidProfilePhoto
      address
      contact
      updatedAt
      externalLinks {
        name
        url
      }
      isFreezed
      isVerified
      isAssigned
      updatedBy {
        name
        role
      }
      createdAt
      assignedUser {
        _id
        name
        role
        contact
        email
      }
      status
    }
  }
`;
export const GET_MASJIDS_COUNT = gql`
  query totalMasjids {
    totalMasjids
  }
`;
export const GET_SORTED_MASJIDS = gql`
  query GetMasjids($filter: JSON, $sort: JSON, $page: Int!, $limit: Int!) {
    getMasjids(filter: $filter, sort: $sort, page: $page, limit: $limit) {
      subscribers
      followers
      _id
      masjidName
      masjidProfilePhoto
      address
      contact
      updatedAt
      externalLinks {
        name
        url
      }
      isFreezed
      isVerified
      isAssigned
      updatedBy {
        name
        role
      }
      createdAt
      assignedUser {
        _id
        name
        role
        contact
        email
      }
      status
    }
  }
`;
export const GET_MASJIDS_FOR_COUNT = gql`
  query GetMasjids($limit: Int, $page: Int) {
    getMasjids(limit: $limit, page: $page) {
      _id
    }
  }
`;

export const GET_FREEZED_MASJIDS = gql`
  query GetFreezedMasjids {
    getFreezedMasjids {
      subscribers
      followers
      _id
      masjidName
      masjidProfilePhoto
      address
      contact
      updatedAt
      externalLinks {
        name
        url
      }
      isFreezed
      isVerified
      isAssigned
      updatedBy {
        name
        role
      }
      createdAt
      assignedUser {
        _id
        name
        role
        contact
        email
      }
      status
    }
  }
`;

export const Get_PROGRAMS = gql`
  query GetPrograms($masjidId: String!) {
    GetPrograms(masjidId: $masjidId) {
      programName
      description
      availableSeats
      category
      description
      location {
        type
        coordinates
      }
      metaData {
        startDate
        endDate
        recurrenceType
        recurrenceInterval
      }
      ageRange {
        minimumAge
        maximumAge
      }
      timings {
        startTime
        endTime
      }
      isPaid
      cost
      capacity
      address
      programPhotos
      isRegistrationRequired
      _id
    }
  }
`;
export const GetUsers = gql`
  query GetUsers($limit: Int, $type: String) {
    users(limit: $limit, type: $type) {
      _id
      name
      masjidDetails {
        _id
        masjidName
        masjidProfilePhoto
        address
        contact
        externalLinks {
          name
          url
        }
        isFreezed
        isVerified
        isAssigned
        assignedUser {
          _id
          name
          role
          contact
          email
        }
        status
      }
      isAddedFromAdmin
      contact
      isApproved
      email
      role
      autoPrefillingTiming
      isVerified
      profilePhoto
      isVerified
      isFreezed
      createdAt
      updatedAt
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($masjidId: String!) {
    getMessages(masjidId: $masjidId) {
      _id
      body
      senderUser {
        _id
        name
        email
      }
      createdAt
    }
  }
`;

export const FETCH_LATEST_UPDATED_EVENTS_QUERY = gql`
  query GetEventByMasjids(
    $page: Int
    $limit: Int
    $sortBy: String
    $sortOrder: String
  ) {
    getEventByMasjids(
      page: $page
      limit: $limit
      sortBy: $sortBy
      sortOrder: $sortOrder
    ) {
      events {
        _id
        eventName
        description
        location {
          type
          coordinates
        }
        metaData {
          startDate
          endDate
          recurrenceType
          recurrenceInterval
        }
        cost
        capacity
      }
    }
  }
`;

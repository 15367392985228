import React, { useCallback, useEffect, useState } from "react";
import { FetchingAnnouncementNotification } from "../../../Redux/Actions/NotificationActions/FetchingAnnouncement";
import "./AnnouncementCarousel.css";
import { FetchingAdmins } from "../../../Redux/Actions/AdminActions/FetchingAdmins";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import EditIcon from "../../../Photos/Newuiphotos/Icons/el_edit.png";
import DeleteIcon from "../../../Photos/Newuiphotos/Icons/delete.svg";

import { Admin, AnnouncementType, Masjid } from "../../../../Types";
import { useAppThunkDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  Avatar,
  Box,
  Button,
  debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import SearchDropdown from "../../AdminComponents/SearchDropdown/SearchDropdown";
import { useSearchMasjids } from "../../../graphql/Masjids/query";
import announcementIcon from "../../../Photos/Newuiphotos/nav bar/navicons/announcement.svg";
import { useGetAdminById } from "../../../graphql/Admins/query";
import toast from "react-hot-toast";
import AnnouncementFormComponent from "../AnnouncementFormComponent/AnnouncementFormComponent";
import { AnnouncementNotificationDelete } from "../../../Redux/Actions/NotificationActions/AnnouncementNotificationDeleteAction";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
type AnnouncementCarouselComponentProps = {
  setViewingAnnouncement: (value: boolean) => void;
};

const AnnouncementCard = ({
  announcement,
  // adminName,
  onClick,
}: {
  announcement: AnnouncementType;
  // adminName: string;
  onClick: (announcementId: string) => void;
}) => {
  return (
    <Card
      className="AnnouncementsCard"
      onClick={() => {
        onClick(announcement._id);
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {announcement.title}
        </Typography>
        {/* <Typography variant="h6" gutterBottom>
          {announcement.masjids[0]?.masjidName}
        </Typography> */}
        {/* <Avatar
          alt={announcement.title}
          src={announcement.image || announcementIcon}
          variant="square"
          sx={{
            width: "100%",
            height: "150px",
            marginBottom: "1rem",
            objectFit: !announcement?.image ? "contain" : "cover",
            "& .MuiAvatar-img": {
              width: !announcement?.image ? "100px" : "100%",
              height: !announcement?.image ? "auto" : "100%",
            },
          }}
        /> */}
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Announced on:{" "}
          {moment(announcement.createdAt).format("DD MMM,YYYY hh:mm a")}
        </Typography>

        {/* <Box className="announcements-body-tx"> */}
        {/* <Typography variant="subtitle2" color="textSecondary">
            Masjids:{" "}
            {announcement?.masjids &&
            Array.isArray(announcement.masjids) &&
            announcement.masjids.length > 0
              ? announcement.masjids
                  .map((masjid) => masjid.masjidName)
                  .join(", ")
              : "All"}
          </Typography> */}
        <Typography variant="body1" component="p">
          {announcement.body.length > 60
            ? `${announcement.body.slice(0, 57)}...`
            : announcement.body}
        </Typography>
        {/* </Box> */}
      </CardContent>
    </Card>
  );
};

const AnnouncementCarouselComponent = ({
  setViewingAnnouncement,
}: AnnouncementCarouselComponentProps) => {
  const dispatch = useAppThunkDispatch();
  const [announcements, setAnnouncements] = useState<AnnouncementType[]>([]);
  const [announcementsByMasjid, setAnnouncementsByMasjid] = useState<
    AnnouncementType[]
  >([]);
  const admins = useAppSelector((state) => state.allAdmins);
  const [masjidsList, setMasjidsList] = useState<Masjid[]>([]);
  const [selectedMasjid, setSelectedMasjid] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [openAnnouncement, setOpenAnnouncement] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<AnnouncementType | null>(null);
  const [selectedAnnouncementAdmin, setSelectedAnnouncementAdmin] =
    useState<string>("");
  const [skipFetchAdmin, setSkipFetchAdmin] = useState(true);
  const { masjids, loading, error } = useSearchMasjids(searchQuery);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    loading: loadingAdmin,
    error: errorAdmin,
    data: dataAdmin,
    refetch: refetchAdmin,
  } = useGetAdminById(
    selectedAnnouncement?.createdBy ?? "",
    !selectedAnnouncement?.createdBy || skipFetchAdmin
  );
  const [refetch, setRefetch] = useState(false);
  // Update masjidList when data is fetched
  useEffect(() => {
    if (masjids && !loading && !error) {
      setMasjidsList(masjids); // Update the list with fetched masjids
    }
  }, [masjids]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await dispatch(FetchingAnnouncementNotification());
      if (result) {
        setIsEditing(false);
        setSelectedAnnouncement(null);
        setSelectedMasjid(null);
        setSearchQuery("");
        setAnnouncements(result);
        setAnnouncementsByMasjid(result);
      }
    };

    fetchData();
  }, [refetch]);
  const refetchAnnoucements = () => {
    setRefetch((prev) => !prev);
  };
  useEffect(() => {
    console.log(dataAdmin, loadingAdmin, errorAdmin);
    if (dataAdmin && !loadingAdmin) {
      setSelectedAnnouncementAdmin(dataAdmin?.name ?? "");
      setSkipFetchAdmin(true);
    } else if (!dataAdmin && !skipFetchAdmin && !loadingAdmin) {
      // setSelectedAnnouncementAdmin("");
      setSkipFetchAdmin(true);
    } else if (errorAdmin && !loadingAdmin) {
      // setSelectedAnnouncementAdmin("");
      toast.error("Failed to Fetch Admin Name");
      setSkipFetchAdmin(true);
    }
  }, [dataAdmin, loadingAdmin, errorAdmin]);
  const handleGetNotifications = (id: string) => {
    // console.log("this si form announcements => ", announcements.find(item=>item.title==="riyad"));
    console.log(announcements);
    if (!id) {
      setAnnouncementsByMasjid(announcements);
      return;
    }
    let res = announcements.filter(
      (item: AnnouncementType) => item.masjidId === id
    );
    console.log("res =>", res);
    setAnnouncementsByMasjid(res);
  };

  const deleteAnnouncement = (announcementId: string) => {
    // data.then(function (result: ResponseType) {
    const data = dispatch(AnnouncementNotificationDelete(announcementId));
    data.then((result) => {
      if (result.success) {
        refetchAnnoucements();
      }
    });
  };
  const handleClick = () => {
    setViewingAnnouncement(false);
  };
  const onClickCard = (announcementId: string) => {
    const announcement = announcements.find(
      (item) => item._id === announcementId
    );
    if (announcement) {
      setOpenAnnouncement(true);
      setSelectedAnnouncement(announcement);
    }
  };

  useEffect(() => {
    if (openAnnouncement && selectedAnnouncement) {
      setSkipFetchAdmin(false);
    }
  }, [openAnnouncement, selectedAnnouncement]);
  // const handleChange = (
  //   event: React.ChangeEvent<{
  //     name?: string | undefined;
  //     value: unknown;
  //   }>
  // ) => {
  //   setSelectedOption(event.target.value as string);
  //   handleGetNotifications(event.target.value as string);
  // };
  const debouncedSearchMasjids = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [searchQuery]
  );
  const handleSearchMasjid = (query: string) => {
    debouncedSearchMasjids(query);
  };
  if (isEditing) {
    return (
      <AnnouncementFormComponent
        setViewingAnnouncement={(edit) => {
          setIsEditing(!edit);
        }}
        existingAnnouncement={selectedAnnouncement}
        refetchAnnoucements={refetchAnnoucements}
      />
    );
  }

  return (
    <>
      <ConfirmationModal
        open={showConfirmation}
        onYes={() => {
          deleteAnnouncement(selectedAnnouncement?._id ?? "");
          setShowConfirmation(false);
        }}
        onNo={() => setShowConfirmation(false)}
        message={"Are you sure you want to delete this announcement?"}
      />
      {selectedAnnouncement && (
        <Dialog
          open={openAnnouncement && !!selectedAnnouncement}
          onClose={() => {
            setOpenAnnouncement(false);
            setSelectedAnnouncement(null);
          }}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{selectedAnnouncement?.title ?? ""}</DialogTitle>
          <DialogContent>
            <Avatar
              alt={selectedAnnouncement.title}
              src={selectedAnnouncement.image || announcementIcon}
              variant="square"
              sx={{
                width: "100%",
                height: "150px",
                marginBottom: "1rem",
                objectFit: "contain",
                "& .MuiAvatar-img": {
                  width: !selectedAnnouncement?.image ? "100px" : "100%",
                  height: !selectedAnnouncement?.image ? "auto" : "100%",
                  objectFit: "contain",
                },
              }}
            />

            {/* {selectedAnnouncement?.isSticky && (
              <Box style={{ position: "relative" }}>
                <IconButton
                  onClick={() => {
                    setIsEditing(true);
                  }}
                  sx={{ position: "absolute", right: "40px", top: "0px" }}
                >
                  <img src={EditIcon} alt="" style={{ width: "15px" }} />
                </IconButton>
              </Box>
            )} */}
            {/* {selectedAnnouncement?._id && (
              <Box style={{ position: "relative" }}>
                <IconButton
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                  sx={{ position: "absolute", right: "10px", top: "0px" }}
                >
                  <img src={DeleteIcon} alt="" style={{ width: "12px" }} />
                </IconButton>
              </Box>
            )} */}
            <Typography variant="subtitle1" gutterBottom>
              Announced on:{" "}
              {moment(selectedAnnouncement.createdAt).format(
                "DD MMM, YYYY hh:mm a"
              )}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Is Sticky: {selectedAnnouncement.isSticky ? "Yes" : "No"}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Expires At:{" "}
              {moment(selectedAnnouncement.expiresAt).format(
                "DD MMM, YYYY hh:mm a"
              )}
            </Typography>
            {selectedAnnouncementAdmin && (
              <Typography variant="subtitle1" gutterBottom>
                Announced by:{" "}
                {loadingAdmin ? "Loading..." : selectedAnnouncementAdmin ?? ""}
              </Typography>
            )}
            <Typography
              variant="body1"
              style={{
                fontWeight: "600",
              }}
            >
              Announcement Body:{" "}
            </Typography>
            <Box
              className="announcements-body-tx"
              style={{ marginBottom: "10px" }}
            >
              <Typography variant="body2">
                {selectedAnnouncement.body}
              </Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              Masjids:{" "}
              {selectedAnnouncement.masjids &&
              Array.isArray(selectedAnnouncement.masjids) &&
              selectedAnnouncement.masjids.length > 0
                ? selectedAnnouncement.masjids
                    .map((masjid) => masjid.masjidName)
                    .join(", ")
                : "All"}
            </Typography>
            <Button
              onClick={() => {
                setOpenAnnouncement(false);
                setSelectedAnnouncement(null);
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogContent>
        </Dialog>
      )}
      <div className="AnnouncementCarouselComponent">
        <div className="AnnouncementCarouselMainContainer">
          <div className="AnnouncementTitleContainer">
            <span className="AnnouncementsTitle">
              Past Announcements By You
            </span>
            <div style={{ width: "50%" }}>
              {masjidsList && (
                <SearchDropdown
                  options={masjidsList || []}
                  placeholder="Type To Search Masjid."
                  onInputChange={(query) => {
                    if (query === "") {
                      setSelectedMasjid("");
                    }

                    handleSearchMasjid(query);

                    // handleSearchMasjid?.(query);
                  }} // Call the search function
                  onSelect={(selectedOption: any) => {
                    console.log(selectedOption);
                    if (selectedOption) {
                      setSelectedMasjid(selectedOption._id);
                      handleGetNotifications(selectedOption._id);
                    }
                  }}
                  error={false}
                  selectedValue={selectedMasjid}
                  loading={loading}
                  clearData={() => {
                    setSelectedMasjid("");
                    handleGetNotifications("");
                  }}
                />
              )}
            </div>
            <MdCancel
              data-testid="CancelIocnAnnouncements"
              className="CancelIocnAnnouncements"
              onClick={handleClick}
            />
          </div>

          <div className="AnnouncementsCarousel">
            {announcementsByMasjid?.length > 0 ? (
              announcementsByMasjid.map((item: AnnouncementType) => {
                // const admin = admins.find(
                //   (admin: Admin) => admin._id === item?.createdBy
                // );
                // const adminName = admin ? admin.name : "Unknown";
                return (
                  <AnnouncementCard
                    key={item._id}
                    announcement={item}
                    // adminName={adminName}
                    onClick={onClickCard}
                  />
                );
              })
            ) : (
              <div className="EventsContainer">
                <h5 style={{ marginLeft: "10px" }}>
                  Sorry!! No Announcements Found
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementCarouselComponent;

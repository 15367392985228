import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MasjidPersonsList from "./MasjidList/MasjidList";
import ChatWindow from "./ChatWindow/ChatWindow";
import MessageInput from "./MessageInput/MessageInput";
import PageContainer from "../../Pages/Shared/PageContainer/PageContainer";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "@apollo/client";
import { GET_MEMBERS, GET_MESSAGES } from "../../graphql/queries";
import { adminFromLocalStg } from "../../helpers/AdminFromLocalStorage/AdminFromLocalStorage";
import {
  CREATE_MEMBER,
  CREATE_MESSAGE,
  DELETE_MEMBER,
  UPDATE_MEMBER,
} from "../../graphql/mutations";
import {
  CreateMemberInput,
  CreateMemberResponse,
  Message,
} from "../../../Types";
import toast from "react-hot-toast";
import SearchFilter from "./SearchFilter/SearchFilter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const useStyles = makeStyles(() => ({
  addPersonInput: {
    borderRadius: "8px",
    padding: "10px",
    backgroundColor: "white",
    boxShadow:
      "0px 4px 10px rgba(0, 0, 0, 0.3), 0px 4px 20px rgba(0, 0, 0, 0.2)",
  },
  messageContainer: {
    height: "500px",
    display: "flex",
    flexDirection: "column",
  },
  messageTop: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    margin: "auto 10px",
  },
  personDetails: {
    "& h5": {
      margin: "15px 0 0 0",
      fontSize: "15px",
    },
    "& p": {
      margin: "0px",
      fontSize: "14px",
    },
    "& .MuiGrid-item": {
      marginBottom: "12px",
    },
  },
  dialogBox: {
    "& .MuiPaper-root": {
      minWidth: "30%",
      borderRadius: "10px",
    },
  },
}));

interface MessageData {
  message: string;
  author: string;
  fromInternalTeam: boolean;
}

export interface Person {
  _id: string;
  personName: string;
  email: string;
  phoneNumber: string;

  designation: string;
  linkedIn: string;
}
const initialPerson: Person = {
  _id: "",
  personName: "",
  email: "",
  phoneNumber: "",
  designation: "",
  linkedIn: "",
};

const CRM: React.FC = ({ consumerMasjidId }: any) => {
  const [selectedPerson, setSelectedPerson] = useState<Person>(initialPerson);
  const [inputMessage, setInputMessage] = useState<string>("");
  const [addEditModalOpen, setAddEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloadMsg, setReloadMsg] = useState(false);
  const [personName, setPersonName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  // const loggedInUserInfo = adminFromLocalStg();
  // const consumerMasjidId = loggedInUserInfo.masjids[0];
  const [filteredMessages, setFilteredMessages] = useState<Message[] | []>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [nameError, setNameError] = useState(false);
  const [selectedPersonId, setSelectedPersonId] = useState("");

  const [createMessage, { loading: messageLoading }] =
    useMutation(CREATE_MESSAGE);
  const [deleteMember, { loading: deleteLoading }] = useMutation(
    DELETE_MEMBER,
    {
      variables: { id: selectedPerson._id },
    }
  );

  console.log("SelectedPersonId", selectedPersonId);
  const handleSendMessage = () => {
    const input = {
      masjidId: consumerMasjidId,
      body: inputMessage,
    };

    // const loadingToastId = toast.loading("loading...");
    createMessage({
      variables: {
        message: {
          masjidId: consumerMasjidId,
          body: inputMessage,
        },
      },
    })
      .then((response) => {
        setReloadMsg(true);
        console.log("response => ", response);
        console.log("Message send:", response.data.createMessage);
        setInputMessage("");
        setTimeout(() => {
          setReloadMsg(false);
        }, 2000);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Error sending message...");
        console.error("Error sending message:", error);
        setReloadMsg(true);
        setTimeout(() => {
          setReloadMsg(false);
        }, 2000);
      });
  };
  const { loading, error, data, refetch } = useQuery(GET_MESSAGES, {
    variables: { masjidId: consumerMasjidId },
  });

  const handleAddOpenModal = () => {
    setNameError(false);
    emptyStateHandler();
    setAddEditModalOpen(true);
  };
  const handleEditModalOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setNameError(false);
    setViewModalOpen(false);
    setAddEditModalOpen(true);
    setIsEditing(true);
  };
  const handleDeleteModalOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setNameError(false);
    setViewModalOpen(false);
    setDeleteModalOpen(true);
    // setIsEditing(true);
  };
  const handleDeleteMember = () => {
    toast.loading("Deleting Member...");
    deleteMember()
      .then((response) => {
        setReload(true);
        toast.dismiss();
        toast.success("Member Deleted Successfully");
        setDeleteModalOpen(false);
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Failed to Delete Member");
        console.error("Error deleting member:", error);
        setDeleteModalOpen(false);
      });
  };
  const payload: CreateMemberInput = {
    personName,
    masjidId: consumerMasjidId,
    email,
    phoneNumber,
    designation,
    img: "", // or undefined if it's optional
  };
  // const CREATE_MEMBER_MUTATION = CREATE_MEMBER(payload);
  // const [createMember, { loading: memberLoading }] = useMutation(
  //   CREATE_MEMBER,
  //   { variables: {} }
  // );

  const [
    createMember,
    { loading: loadingMember, error: memberError, data: memberData },
  ] = useMutation(CREATE_MEMBER);

  const [
    updateMember,
    {
      loading: loadingUpdateMember,
      error: memberUpdateError,
      data: memberUpdateData,
    },
  ] = useMutation(UPDATE_MEMBER);

  const handleCloseAddEditModal = () => {
    setAddEditModalOpen(false);
    setIsEditing(false);
    setSelectedPersonId("");
  };
  const handleCloseViewModal = () => {
    setSelectedPersonId("");
    setViewModalOpen(false);
  };

  const emptyStateHandler = () => {
    setPersonName("");
    setEmail("");
    setPhoneNumber("");
    setDesignation("");
    setLinkedIn("");
    handleCloseAddEditModal();
    setIsEditing(false);
  };
  const handleSavePerson = () => {
    if (personName) {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const cleanedPhone = phoneNumber.replace(
        /[\u200E\u200F\u202A-\u202E]/g,
        ""
      );

      if (email !== "" && !re.test(email)) {
        toast.error("Please enter a valid email address.");
      } else if (
        cleanedPhone !== "" &&
        !/^[0-9+\-\s()]+$/.test(cleanedPhone.trim())
      ) {
        toast.error("Please enter a valid phone number");
      } else {
        setNameError(false);
        const loadingToastId = toast.loading("loading...");
        if (isEditing) {
          updateMember({
            variables: {
              input: {
                _id: selectedPersonId,
                personName: personName,
                email: email,
                phoneNumber: phoneNumber.trim(),
                designation: designation,
                linkedIn: linkedIn,
                img: "",
              },
            },
          })
            .then((response) => {
              setReload(true);
              toast.dismiss(loadingToastId);
              setTimeout(() => {
                setReload(false);
              }, 2000);
              toast.success("Successfully Updated Member");
            })
            .catch((error) => {
              toast.dismiss(loadingToastId);
              toast.error("Error Updating Member");
              setTimeout(() => {
                toast.dismiss();
              }, 2000);
              console.error("Error updating member:", error);
            });
          emptyStateHandler();
        } else {
          createMember({
            variables: {
              input: {
                personName: personName,
                masjidId: consumerMasjidId,
                email: email,
                phoneNumber: phoneNumber.trim(),
                designation: designation,
                linkedIn: linkedIn,
                img: "", // or undefined if it's optional
              },
            },
          })
            .then((response) => {
              setReload(true);
              toast.dismiss(loadingToastId);
              setTimeout(() => {
                setReload(false);
              }, 2000);
              toast.success("Successfully Created Member");
            })
            .catch((error) => {
              toast.dismiss(loadingToastId);
              toast.error("Error Creating Member");
              setTimeout(() => {
                toast.dismiss();
              }, 2000);
              console.error("Error creating member:", error);
            });
          emptyStateHandler();
        }
      }
    } else {
      setNameError(true);
      toast.error("Please fill all the required fields!");
    }
  };

  const classes = useStyles();

  const selectedPersonHandler = (target: any, person: Person) => {
    // setAddEditModalOpen(true);
    setViewModalOpen(true);
    // setIsEditing(true);
    setPersonName(person.personName);
    setEmail(person.email);
    setPhoneNumber(person.phoneNumber);
    setDesignation(person.designation);
    setLinkedIn(person.linkedIn);
    setSelectedPersonId(person._id);

    setSelectedPerson({
      personName: person.personName,
      email: person.email,
      phoneNumber: person.phoneNumber,
      designation: person.designation,
      linkedIn: person.linkedIn,
      _id: person._id,
    });
  };

  useEffect(() => {
    if (reloadMsg) {
      refetch();
    }
  }, [reloadMsg, refetch]);

  useEffect(() => {
    if (data) {
      console.log("result", data);
      const filteredMessages = data?.getMessages?.filter(
        (message: Message) =>
          message.body.toLowerCase().includes(searchTerm.toLowerCase()) ||
          message.senderUser.email
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          message.senderUser.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        // ||
        // `${new Date(message.createdAt).getMonth() + 1}-${new Date(
        //   message.createdAt
        // ).getDate()}-${new Date(message.createdAt).getFullYear()}`.includes(
        //   searchTerm.toLowerCase()
        // )
      );

      setFilteredMessages(filteredMessages);
    }
  }, [data, searchTerm]);

  const handleCopy = (value: any) => {
    navigator.clipboard.writeText(value);
    toast.success("Copied to clipboard");
  };
  return (
    <>
      <Container style={{ paddingTop: "10px", maxWidth: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box className={classes.addPersonInput}>
              <Paper sx={{ border: "none", boxShadow: "none" }}>
                <Box className={classes.messageTop}>
                  <Typography
                    variant="h6"
                    style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
                  >
                    Masjid's Member
                  </Typography>

                  <Box>
                    <DataSaverOnIcon onClick={handleAddOpenModal} />
                  </Box>
                </Box>

                <MasjidPersonsList
                  reload={reload}
                  masjidId={consumerMasjidId}
                  selectedPerson={selectedPerson}
                  selectedPersonHandler={selectedPersonHandler}
                  setReload={setReload}
                />
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Paper className={classes.messageContainer}>
              <SearchFilter
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
              <ChatWindow messages={filteredMessages} />
              <MessageInput
                onSendMessage={handleSendMessage}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={addEditModalOpen || deleteModalOpen}
        onClose={() => {
          addEditModalOpen
            ? handleCloseAddEditModal()
            : setDeleteModalOpen(false);
        }}
        className={classes.dialogBox}
      >
        {addEditModalOpen ? (
          <>
            <DialogTitle>
              {isEditing ? "Edit Person" : "Add Person"}
            </DialogTitle>

            <DialogContent>
              <TextField
                required
                sx={{ border: nameError ? "1px solid red" : "" }}
                autoFocus
                margin="dense"
                label="Person Name"
                fullWidth
                value={personName}
                onChange={(e) => {
                  setNameError(false);
                  setPersonName(e.target.value);
                }}
              />
              <TextField
                margin="dense"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Phone Number"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <TextField
                margin="dense"
                label="Designation"
                fullWidth
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
              <TextField
                margin="dense"
                label="LinkedIn Url"
                fullWidth
                value={linkedIn}
                onChange={(e) => setLinkedIn(e.target.value)}
              />
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  if (isEditing) {
                    setIsEditing(false);
                    setAddEditModalOpen(false);
                    setViewModalOpen(true);
                    setPersonName(selectedPerson.personName);
                    setPhoneNumber(selectedPerson.phoneNumber);
                    setDesignation(selectedPerson.designation);
                    setLinkedIn(selectedPerson.linkedIn);
                    setEmail(selectedPerson.email);
                  } else {
                    setAddEditModalOpen(false);
                    // setViewModalOpen(true);
                  }
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleSavePerson} color="primary">
                Save
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to Delete this Member ?`}
            </DialogTitle>
            <DialogActions style={{ justifyContent: "space-around" }}>
              <Button
                onClick={() => setDeleteModalOpen(false)}
                variant="contained"
                color="inherit"
                // disabled={isUnFreezing}
              >
                No
              </Button>
              <Button
                onClick={handleDeleteMember}
                variant="contained"
                color="primary"
              >
                {/* {isUnFreezing ? (
                  <CircularProgress
                    // color="black"
                    size="15px"
                  />
                ) : ( */}
                <span> Yes </span>
                {/* )}{" "} */}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={viewModalOpen}
        onClose={handleCloseViewModal}
        className={classes.dialogBox}
      >
        <DialogTitle>Member Details</DialogTitle>

        <DialogContent className={classes.personDetails}>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <div>
                <h5>
                  Name{" "}
                  {selectedPerson.personName && (
                    <IconButton
                      onClick={() => {
                        handleCopy(selectedPerson?.personName);
                      }}
                    >
                      {" "}
                      <ContentCopyIcon />
                    </IconButton>
                  )}
                </h5>
                <p
                  style={
                    !selectedPerson?.personName ? { color: "#F93737" } : {}
                  }
                >
                  {/* <div style={{ display: "flex" }}> */}
                  {selectedPerson?.personName ?? "Not Available"} {/* </div> */}
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h5>
                  Email{" "}
                  {selectedPerson?.email && (
                    <IconButton
                      onClick={() => {
                        handleCopy(selectedPerson?.email);
                      }}
                    >
                      {" "}
                      <ContentCopyIcon />
                    </IconButton>
                  )}
                </h5>
                <p style={!selectedPerson?.email ? { color: "#F93737" } : {}}>
                  {selectedPerson?.email ?? "Not Available"}
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h5>
                  Phone Number
                  {/* {selectedPerson?.phoneNumber && (
                    <IconButton
                      onClick={() => {
                        handleCopy(selectedPerson?.phoneNumber);
                      }}
                    >
                      {" "}
                      <ContentCopyIcon />
                    </IconButton>
                  )} */}
                </h5>
                <p
                  style={
                    !selectedPerson?.phoneNumber ? { color: "#F93737" } : {}
                  }
                >
                  {selectedPerson?.phoneNumber
                    ? selectedPerson?.phoneNumber.split(",").map((number) => (
                        <React.Fragment key={number}>
                          <p>
                            {number}
                            <IconButton
                              onClick={() => {
                                handleCopy(number);
                              }}
                            >
                              <ContentCopyIcon />
                            </IconButton>
                          </p>
                        </React.Fragment>
                      ))
                    : "Not Available"}
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h5>
                  Designation{" "}
                  {selectedPerson?.designation && (
                    <IconButton
                      onClick={() => {
                        handleCopy(selectedPerson?.designation);
                      }}
                    >
                      {" "}
                      <ContentCopyIcon />
                    </IconButton>
                  )}
                </h5>
                <p
                  style={
                    !selectedPerson?.designation ? { color: "#F93737" } : {}
                  }
                >
                  {selectedPerson?.designation ?? "Not Available"}
                </p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <h5>
                  LinkedIn Url
                  {selectedPerson?.linkedIn && (
                    <IconButton
                      onClick={() => {
                        handleCopy(selectedPerson?.linkedIn);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  )}
                </h5>
                <p
                  style={!selectedPerson?.linkedIn ? { color: "#F93737" } : {}}
                >
                  {selectedPerson.linkedIn ?? "Not Available"}
                </p>
              </div>
            </Grid>
          </Grid>
          <IconButton
            style={{ position: "absolute", top: "5px", right: "5px" }}
            onClick={handleEditModalOpen}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            style={{
              position: "absolute",
              top: "5px",
              right: "35px",
              color: "#FF5B5B",
            }}
            onClick={handleDeleteModalOpen}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CRM;

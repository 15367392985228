import { CHANGE_HIDDEN_COLUMNS } from "../../ActionType";
import { TableColumnAction } from "../../../../Types";

type InitialState = {
  hiddenColumns: string[];
};

const initialState: InitialState = {
  hiddenColumns: ["createdAt"],
};

// eslint-disable-next-line import/no-anonymous-default-export
const MasjidTableColumnsReducer = (
  state = initialState,
  action: TableColumnAction
): InitialState => {
  switch (action.type) {
    case CHANGE_HIDDEN_COLUMNS:
      const { hiddenColumns } = action.payload;
      return {
        ...state,
        hiddenColumns,
      };

    default:
      return state;
  }
};

export default MasjidTableColumnsReducer;

import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // 1) Import Copy Icon
import moment from "moment";
import React from "react";
import toast from "react-hot-toast";

import DetailFields from "../../../VerificationCenterComponents/Modal/DetailFields";
import DetailsButtons from "../../../VerificationCenterComponents/Modal/DetailsButtons";
import customScrollarStyles from "../../../Shared/CustomScrollBar.module.css";
import { Masjid } from "../../../../../Types";
import { Colors } from "../../../../constants/Colors/colors";
import { socialLinksHandler } from "../../../../helpers/MasjidExternalLinkHandler";
import { roleRenamer } from "../../../../helpers/RoleRenamer";
import { AdminRole, MasjidStatus } from "../Enums/enums";
import approvedBtnTick from "../../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import rejectedBtnCross from "../../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import StatusLogs from "../../../Shared/StatusLogs/StatusLogs";
import ReasonConfirmation from "../../../Shared/ReasonConfirmation/ReasonConfirmation";
import { getFormattedMasjidStatus, getStatus, getValidUrl } from "../Helper";

interface MasjidDetailsProps {
  selectedMasjid: Masjid;
  logs: any[];
  isLoading: boolean;
  showReasonConfirmation: boolean;
  setShowReasonConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  showLogs: boolean;
  setShowLogs: React.Dispatch<React.SetStateAction<boolean>>;
  changingStatusTo: string;
  setChangingStatusTo: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: (status: string, reason: string) => void;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reasonError: boolean;
  setReasonError: React.Dispatch<React.SetStateAction<boolean>>;
}

const ValueFieldStyles: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#1B8368",
  fontWeight: "bold",
};

const getValueFieldStyles = (value: any) => {
  if (value) {
    return ValueFieldStyles;
  } else {
    return { ...ValueFieldStyles, color: "#FF4949" };
  }
};

const MasjidDetails = ({
  selectedMasjid,
  logs,
  isLoading,
  showReasonConfirmation,
  setShowReasonConfirmation,
  showLogs,
  setShowLogs,
  changingStatusTo,
  setChangingStatusTo,
  handleSubmit,
  reason,
  setReason,
  reasonError,
  setReasonError,
}: MasjidDetailsProps) => {
  const masjidStatus = getStatus(
    selectedMasjid?.isFreezed,
    selectedMasjid?.isVerified
  );

  // ------------------- COPY HELPER -------------------
  const handleCopy = (text: string | undefined) => {
    if (!text) return;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy!");
      });
  };
  // ---------------------------------------------------

  const handleVerifyReject = (status: string) => {
    setChangingStatusTo(status);
    setShowReasonConfirmation(true);
  };

  const handleConfirm = (status: string) => {
    if (!reason) {
      setReasonError(true);
      return;
    }
    handleSubmit(status, reason);
  };

  return (
    <DialogContent
      className={customScrollarStyles["custom-scrollbar"]}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: isLoading ? "center" : "flex-start",
        overflowX: "hidden",
        minHeight: showLogs && logs?.length > 0 ? "auto" : "400px",
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : showReasonConfirmation ? (
        <ReasonConfirmation
          onConfirm={handleConfirm}
          newStatus={changingStatusTo}
          reason={reason}
          setReason={setReason}
          reasonError={reasonError}
          setReasonError={setReasonError}
          isLoading={isLoading}
        />
      ) : showLogs ? (
        <StatusLogs logs={logs} feature={"Masjid Verification"} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "auto",
          }}
        >
          <Box>
            {/* ---------- Status ---------- */}
            <Typography variant="subtitle2">Status</Typography>
            <Typography
              variant="body2"
              sx={{
                color:
                  masjidStatus === MasjidStatus.VERIFIED ||
                  masjidStatus === null
                    ? Colors.neonGreen
                    : masjidStatus === MasjidStatus.UNVERIFIED
                    ? Colors.warningYellow
                    : Colors.rejectedRed,
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {getFormattedMasjidStatus(masjidStatus) ?? ""}
            </Typography>

            {/* ---------- Masjid Address ---------- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Masjid Address
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedMasjid?.address)}
              >
                {selectedMasjid?.address ?? "Not Available"}
              </Typography>
              {selectedMasjid?.address && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(selectedMasjid?.address)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            {/* ---------- Masjid Name ---------- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Masjid Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedMasjid?.masjidName)}
              >
                {selectedMasjid?.masjidName ?? ""}
              </Typography>
              {selectedMasjid?.masjidName && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(selectedMasjid?.masjidName)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            {/* ---------- Masjid Contact Number ---------- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Masjid Contact Number
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedMasjid?.contact)}
              >
                {selectedMasjid?.contact ?? "Not Available"}
              </Typography>
              {selectedMasjid?.contact && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(selectedMasjid?.contact)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            {/* ---------- Masjid Website ---------- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Masjid Website
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {socialLinksHandler("Website", selectedMasjid?.externalLinks) ? (
                <>
                  <Typography variant="body2" sx={ValueFieldStyles}>
                    <a
                      href={getValidUrl(
                        socialLinksHandler(
                          "Website",
                          selectedMasjid?.externalLinks
                        )
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textTransform: "none", color: "#1B8368" }}
                    >
                      {socialLinksHandler(
                        "Website",
                        selectedMasjid?.externalLinks
                      )}
                    </a>
                  </Typography>
                  {/* Copy icon for the website link */}
                  <IconButton
                    size="small"
                    onClick={() =>
                      handleCopy(
                        socialLinksHandler(
                          "Website",
                          selectedMasjid?.externalLinks
                        )
                      )
                    }
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <Typography variant="body2" sx={getValueFieldStyles(false)}>
                  Not Available
                </Typography>
              )}
            </Box>

            {/* ---------- Assigned User Fields (if any) ---------- */}
            {selectedMasjid?.assignedUser?.length > 0 && (
              <>
                {(selectedMasjid.assignedUser[0].role ===
                  AdminRole.MUSALI_ADMIN ||
                  selectedMasjid?.assignedUser[0].role ===
                    AdminRole.SUB_ADMIN) && (
                  <>
                    <Typography variant="subtitle2" sx={{ mt: 2 }}>
                      Role
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography
                        variant="body2"
                        sx={getValueFieldStyles(
                          selectedMasjid?.assignedUser[0]?.role
                        )}
                      >
                        {roleRenamer(
                          selectedMasjid?.assignedUser[0]?.role ?? ""
                        )}
                      </Typography>
                      {selectedMasjid?.assignedUser[0]?.role && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleCopy(
                              roleRenamer(
                                selectedMasjid?.assignedUser[0]?.role ?? ""
                              )
                            )
                          }
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  </>
                )}
                <Typography variant="subtitle2" sx={{ mt: 2 }}>
                  Full Name
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(
                      selectedMasjid?.assignedUser[0]?.name
                    )}
                  >
                    {selectedMasjid?.assignedUser[0]?.name ?? ""}
                  </Typography>
                  {selectedMasjid?.assignedUser[0]?.name && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopy(selectedMasjid?.assignedUser[0]?.name)
                      }
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>

                <Typography variant="subtitle2" sx={{ mt: 2 }}>
                  Email Address
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(
                      selectedMasjid?.assignedUser[0]?.email
                    )}
                  >
                    {selectedMasjid?.assignedUser[0]?.email ?? ""}
                  </Typography>
                  {selectedMasjid?.assignedUser[0]?.email && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopy(selectedMasjid?.assignedUser[0]?.email)
                      }
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>

                <Typography variant="subtitle2" sx={{ mt: 2 }}>
                  Contact Number
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(
                      selectedMasjid?.assignedUser[0]?.contact
                    )}
                  >
                    {selectedMasjid?.assignedUser[0]?.contact ??
                      "Not Available"}
                  </Typography>
                  {selectedMasjid?.assignedUser[0]?.contact && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopy(selectedMasjid?.assignedUser[0]?.contact)
                      }
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </>
            )}

            {/* ---------- Display the first log if Verified/Rejected ---------- */}
            {(masjidStatus === MasjidStatus.VERIFIED ||
              masjidStatus === MasjidStatus.REJECTED) &&
              logs.length > 0 && (
                <>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, textTransform: "capitalize" }}
                  >
                    {getFormattedMasjidStatus(logs[0]?.status) ?? ""} By
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography
                      variant="body2"
                      sx={getValueFieldStyles(logs[0]?.userDetails.name)}
                    >
                      {logs[0]?.userDetails.name ?? ""}
                    </Typography>
                    {logs[0]?.userDetails.name && (
                      <IconButton
                        size="small"
                        onClick={() => handleCopy(logs[0]?.userDetails.name)}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, textTransform: "capitalize" }}
                  >
                    {getFormattedMasjidStatus(logs[0]?.status) ?? ""} On
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography
                      variant="body2"
                      sx={getValueFieldStyles(logs[0]?.createdAt)}
                    >
                      {logs[0]?.createdAt
                        ? moment
                            .utc(logs[0].createdAt)
                            .local()
                            .format("DD MMM, YYYY | hh:mm A")
                        : "Not Available"}
                    </Typography>
                    {logs[0]?.createdAt && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleCopy(
                            moment
                              .utc(logs[0].createdAt)
                              .local()
                              .format("DD MMM, YYYY | hh:mm A")
                          )
                        }
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </>
              )}
          </Box>

          {/* ---------- Verification Buttons / Logs Button ---------- */}
          {masjidStatus === MasjidStatus.UNVERIFIED ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
                gap: 20,
                marginBottom: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "150px",
                  borderRadius: "12px",
                  bgcolor: "#00D23B",
                  "&:hover": { bgcolor: "#00D23B" },
                  textTransform: "none",
                }}
                onClick={() => {
                  handleVerifyReject("approved");
                }}
              >
                <Box
                  component="img"
                  src={approvedBtnTick}
                  width={"14px"}
                  marginRight="5px"
                />
                Verify
              </Button>

              <Button
                variant="contained"
                sx={{
                  width: "150px",
                  borderRadius: "12px",
                  bgcolor: "#F93737",
                  "&:hover": { bgcolor: "#F93737" },
                  textTransform: "none",
                }}
                onClick={() => {
                  handleVerifyReject("rejected");
                }}
              >
                <Box
                  component="img"
                  src={rejectedBtnCross}
                  width={"11px"}
                  marginRight="5px"
                />
                Reject
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "12px",
                mt: 3,
                gap: 2,
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: "#3D5347",
                  textDecoration: "underline",
                  textTransform: "none",
                  fontSize: "13.5px",
                  width: "150px",
                  fontWeight: "600",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  setShowLogs(true);
                }}
              >
                Check Logs
              </Button>
            </Box>
          )}
        </Box>
      )}
    </DialogContent>
  );
};

export default MasjidDetails;

import { Box, Divider, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import styles from "../../Shared/CustomScrollBar.module.css";
import { getFormattedMasjidStatus } from "../../PageComponents/MasjidTable/Helper";
interface StatusLogsProps {
  logs: any;
  feature?: String;
}
const StatusLogs = ({ logs, feature = "" }: StatusLogsProps) => {
  return (
    <Box
      sx={{
        padding: 2,
        width: "100%",
        minHeight: "inherit",
        display: "flex",
        flexDirection: "column",
        justifyContent: logs?.length > 0 ? "flex-start" : "center",
        alignItems: "center",
      }}
    >
      {logs?.length > 0 ? (
        logs
          .slice()
          .reverse()
          .map((item: any, index: number) => (
            <Box sx={{ marginBottom: 2, width: "100%" }}>
              <Typography
                variant="subtitle2"
                sx={{ textTransform: "capitalize" }}
              >
                {getFormattedMasjidStatus(item.status)} By
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#1B8368",
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >
                {item?.adminDetails?.name ?? "Unknown"}
              </Typography>
              <Typography variant="subtitle2" sx={{}}>
                Date & Time
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#1B8368",
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >
                {moment
                  .utc(item.createdAt)
                  .local()
                  .format("DD MMM, YYYY | hh:mm A")}
              </Typography>
              <Typography variant="subtitle2" sx={{ marginBottom: "10px" }}>
                Reason of{" "}
                {item.status === "approved" || item.status === "REGISTERED"
                  ? "Approval"
                  : "Rejection"}
                {feature && ` (${feature})`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginBottom: 2,
                  borderRadius: 2,
                }}
                className={styles["custom-scrollbar"]}
                style={{
                  maxHeight: item.description.length > 180 ? "200px" : "auto",
                  overflowY: item.description.length > 180 ? "auto" : "hidden",
                }}
              >
                {item.description}
              </Typography>

              {index !== logs?.length - 1 && (
                <Divider sx={{ borderWidth: "thin", borderColor: "#e6e2e2" }} />
              )}
            </Box>
          ))
      ) : (
        <div>No Logs Found</div>
      )}
    </Box>
  );
};

export default StatusLogs;

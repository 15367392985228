import React, { useCallback, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, debounce } from "@mui/material";
import { useAppSelector } from "../../../Redux/hooks";
import { Masjid } from "../../../../Types";
import { useSearchMasjids } from "../../../graphql/Masjids/query";

interface Props {
  handleChange: (newValue: Masjid[] | null) => void;
  isMultiple?: boolean;
  selectedMasjids: Masjid[];
}

const MultiMasjidSelectDropdown: React.FC<Props> = ({
  handleChange,
  isMultiple = false,
  selectedMasjids,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { masjids, loading, error } = useSearchMasjids(searchQuery);
  const [masjidsList, setMasjidsList] = useState<Masjid[]>([]);

  // Update masjidList when data is fetched
  useEffect(() => {
    if (masjids && !loading && !error) {
      setMasjidsList(masjids); // Update the list with fetched masjids
    }
  }, [masjids]);

  useEffect(() => {
    console.log(searchQuery);
  }, [searchQuery]);

  const debouncedSearchMasjids = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [searchQuery]
  );
  const handleSearchMasjid = (query: string) => {
    debouncedSearchMasjids(query);
  };

  return (
    <>
      <Autocomplete
        value={selectedMasjids}
        multiple={isMultiple}
        options={masjidsList.filter(
          (masjid) =>
            !selectedMasjids.some(
              (selectedMasjid) => selectedMasjid._id === masjid._id
            )
        )}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        fullWidth
        ListboxProps={{
          style: {
            maxHeight: "200px", // Set the max height to 200px
            overflow: "auto", // Enable scrolling when list exceeds 200px
          },
        }}
        onInputChange={(event, newValue) => {
          console.log("newValueInput:", newValue);
          if (!newValue) {
            setMasjidsList([]);
            return;
          }
          handleSearchMasjid(newValue);
        }}
        onChange={(event, newValue) => {
          console.log("newValue:", newValue);
          handleChange(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Masjid" variant="outlined" />
        )}
        getOptionLabel={(option) => option.masjidName}
        renderOption={(props, option) => (
          <li {...props}>{option.masjidName}</li>
        )}
      />
    </>
  );
};

export default MultiMasjidSelectDropdown;

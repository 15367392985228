import { FETCH_ADMIN_BY_NAME } from "../../ActionType";
import * as api from "../../../APICalls/index";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";
import { Dispatch } from "redux";
import { GET_ADMIN_BY_ID } from "../../../graphql/Admins/query";
import client from "../../../apolloClient";

export const FetchingAdminById = (adminId: string) => async (
  dispatch: Dispatch
) => {
  try {
    const { data, error } = await client.query({
      query: GET_ADMIN_BY_ID,
      variables: { id: adminId },
      fetchPolicy: "network-only",
    });

    if (data) {
      let result = {
        data: data.getAdminById,
        success: true,
      };

      return result;
    }
    throw error;
  } catch (error) {
    handleSnackbar(
      true,
      "error",
      `Fetching Admin Failed: ${error.message}`,
      dispatch
    );
    return error;
  }
};

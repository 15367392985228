import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@mui/material";
import { useAppSelector } from "../../../Redux/hooks";
import { Masjid } from "../../../../Types";
interface Props {
  handleChange: (newValue: Masjid[] | null) => void;
  isMultiple?: boolean;
}

const MasjidsAutocomplete: React.FC<Props> = ({
  handleChange,
  isMultiple = false,
}) => {
  const masjids = useAppSelector((state) => state.masjids);
  return (
    <>
      <Autocomplete
        multiple={isMultiple}
        options={masjids}
        fullWidth
        onChange={(event, newValue) => {
          console.log("newValue:", newValue);
          handleChange(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Masjid" variant="outlined" />
        )}
        getOptionLabel={(option) => option.masjidName}
        renderOption={(props, option) => (
          <li {...props}>{option.masjidName}</li>
        )}
      />
    </>
  );
};

export default MasjidsAutocomplete;

import { FETCH_MASJID, FETCH_MASJID_BY_NAME } from "../../ActionType";
import * as API from "../../../ClientAPICalls/index";
import { ChangeSnackbar } from "../SnackbarActions/SnackbarActions";
import { Dispatch } from "redux";
import client from "../../../apolloClient";
import { GET_MASJID_BY_ID } from "../../../graphql/Masjids/query";
import tz_lookup from "tz-lookup";
import { DocumentNode } from "graphql";

export const FetchMasjidById = (
  id: string,
  customQuery?: DocumentNode
) => async (dispatch: Dispatch<any>) => {
  try {
    // Execute the GraphQL query
    const { data } = await client.query({
      query: customQuery ? customQuery : GET_MASJID_BY_ID,
      variables: { id },
      fetchPolicy: "network-only",
    });

    if (data && data.getMasjidById) {
      // Clone the data to avoid modifying the original object
      const masjidData = { ...data.getMasjidById };
      // Add timezone information using tz_lookup
      console.log(masjidData);
      const longitude = masjidData.location.coordinates[0];
      const latitude = masjidData.location.coordinates[1];
      const timezone = tz_lookup(latitude, longitude);
      masjidData.location = { ...masjidData.location, timezone };

      // Dispatch the data to the Redux store
      dispatch({ type: "FETCH_ADMIN_MASJID", payload: masjidData });

      return masjidData;
    } else {
      throw new Error("Masjid not found");
    }
  } catch (error) {
    console.error("Error fetching masjid data:", error);
    return error.response;
  }
};

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const GET_ALL_ADMIN = "GET_ALL_ADMIN";
export const CHANGE_SIDEBAR = "CHANGE_SIDEBAR";
export const CHANGE_SNACKBAR = "CHANGE_SNACKBAR";
export const CHANGE_TIMEZONE = "CHANGE_TIMEZONE";
export const FETCH_MASJID = "FETCH_MASJID";
export const CHANGE_HIDDEN_COLUMNS = "CHANGE_HIDDEN_COLUMNS";

export const FETCH_MASJIDS = "FETCH_MASJIDS"; //remove reducer

export const FETCH_STALE_MASJIDS = "FETCH_STALE_MASJIDS"; //remove reducer
export const FETCH_EVENTS = "FETCH_EVENTS"; //remove reducer
export const FETCH_MASJID_EVENTS = "FETCH_MASJID_EVENTS"; //remove reducer
export const FETCH_MASJID_BY_NAME = "FETCH_MASJID_BY_NAME"; //remove reducer
export const FETCH_ADMIN_MASJIDS = "FETCH_ADMIN_MASJIDS"; //remove reducer
export const FETCH_ADMIN_BY_NAME = "FETCH_ADMIN_BY_NAME"; //remove reducer

export const ADD_MASJID = "ADD_MASJID"; //remove reducer
export const DELETE_MASJID = "DELETE_MASJID"; //remove reducer
export const UPDATE_MASJID = "UPDATE_MASJID"; //remove reducer

export const ADD_EVENT = "ADD_EVENT"; //remove reducer
export const DELETE_EVENT = "DELETE_EVENT"; //remove reducer
export const UPDATE_EVENT = "UPDATE_EVENT"; //remove reducer
export const FETCH_LATEST_UPDATED_EVENTS = "FETCH_LATEST_UPDATED_EVENTS"; //remove reducer
export const FETCH_LATEST_UPDATED_EVENTS_BY_ADMIN =
  "FETCH_LATEST_UPDATED_EVENTS_BY_ADMIN"; // remove reducer

export const GET_TIMINGS_MASJID_ID = "GET_TIMINGS_MASJID_ID"; //remove reducer
export const GET_TIMINGS_DATE = "GET_TIMINGS_DATE"; //remove reducer
export const ADD_TIMING = "ADD_TIMING"; //remove reducer
export const UPDATE_TIMING = "UPDATE_TIMING"; //remove reducer
export const UPDATE_NAMAAZ = "UPDATE_NAMAAZ"; //remove reducer

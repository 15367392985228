import {
  Button,
  Box,
  Typography,
  IconButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import React, { useState } from "react";
import PopoverComponent from "../Popover/Popover";
import { Masjid } from "../../../../Types";
import toast from "react-hot-toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import {
  getAdminStatus,
  getFormattedAdminStatus,
} from "../../AdminComponents/helpers";

interface adminDetailsProps {
  adminDetails: Masjid["assignedUser"] | null;
}

const AdminDetails = ({ adminDetails }: adminDetailsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopOverButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!adminDetails) {
      toast.error("No Admin found for this Masjid");
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Helper to render a row with label and value in two columns.
  const renderField = (
    label: string,
    value: string | undefined,
    copyable: boolean = true
  ) => (
    <Grid container alignItems="center" spacing={1} sx={{ marginBottom: 1 }}>
      {/* Left column for field name */}
      <Grid item xs={4}>
        <Typography variant="body1" fontWeight="bold" color="textSecondary">
          {label}
        </Typography>
      </Grid>
      {/* Right column for field value (and icon if copyable) */}
      <Grid item xs={8} container justifyContent="flex-end" alignItems="center">
        <Typography
          variant="body1"
          color="textPrimary"
          sx={{ textAlign: "right" }}
        >
          {value}
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          sx={{ textAlign: "right", minWidth: "40px" }}
        >
          {copyable && (
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(value || "");
                toast.dismiss();
                toast.success("Copied to clipboard");
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          )}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <div style={{ position: "relative" }}>
      <Button
        style={{
          border: "1px solid blue",
          color: "blue",
          height: "20px",
          fontSize: "10px",
          marginLeft: "10px",
          marginBottom: "10px",
          cursor: "Pointer",
        }}
        onClick={handlePopOverButtonClick}
      >
        Admin Details
      </Button>

      <PopoverComponent anchorEl={anchorEl} onClose={handlePopoverClose}>
        <Card>
          <CardHeader title="Admin Details" />
          <CardContent>
            {adminDetails?.[0] ? (
              <>
                {renderField("Admin Name:", adminDetails[0].name)}
                {renderField("Admin Email:", adminDetails[0].email)}
                {renderField("Contact:", adminDetails[0].contact)}
                {renderField(
                  "Admin Role:",
                  roleRenamer(adminDetails[0].role ?? ""),
                  false
                )}
                {/* {renderField(
                  "Account Verification Status:",
                  getFormattedAdminStatus(
                    getAdminStatus(
                      adminDetails[0].isFreezed,
                      adminDetails[0].isVerified
                    )
                  ),
                  false
                )} */}
              </>
            ) : (
              <Typography variant="body1" color="textPrimary">
                No Admin found for this Masjid
              </Typography>
            )}
          </CardContent>
        </Card>
      </PopoverComponent>
    </div>
  );
};

export default AdminDetails;

import { gql, useQuery } from "@apollo/client";
import { GetUsers } from "../queries";

export const dummyExternalData = [
  {
    _id: "1",
    name: "Aasif Khan",
    email: "asifkhan@gmail.com",
    contact: "+91 8168999683",
    role: "subadmin",
    status: "active",
    masjid: "Islamic Center of Irving",
    isFreezed: false,
    isVerified: true,
    isAssigned: true,
  },
  {
    _id: "2",
    name: "Bashir",
    email: "bashir@gmail.com",
    contact: "+91 8168999683",
    role: "musaliadmin",
    status: "active",
    masjid: "EPIC Masjid",
    isFreezed: true,
    isVerified: false,
    isAssigned: false,
  },

  {
    _id: "3",
    name: "Mustafa",
    email: "mustafa@gmail.com",
    contact: "+91 8168999683",
    role: "musaliadmin",
    status: "active",
    masjid: "ICQC Masjid",
    isFreezed: false,
    isVerified: true,
    isAssigned: false,
  },
  {
    _id: "4",
    name: "Bibek",
    email: "bibek@gmail.com",
    contact: "+91 8168999683",
    role: "subadmin",
    status: "active",
    masjid: "Masjid at Taqwa",
    isFreezed: true,
    isVerified: false,
    isAssigned: true,
  },
];

export const useExternalUsers = (skip: boolean) => {
  //   const { loading, error, data, refetch } = useQuery(GET_MASJID_BY_ID, {
  //     variables: { id: masjidId },
  //     skip: skip,
  //     fetchPolicy: "network-only",
  //   });
  const { loading, error, data, refetch } = useQuery(GetUsers, {
    variables: { limit: 10000, type: "external" },
    skip: skip,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  return {
    data: data
      ? data.users.filter(
          (user: any) => user.role !== "superadmin" && user.role !== "admin"
        )
      : [],
    loading: loading,
    error: error,
    refetch: refetch,
  };
};
export const dummyInternalData = [
  {
    _id: "1",
    name: "Prabhat",
    email: "prbhat@gmail.com",
    role: "subadmin",
    status: "active",
    masjid: "Islamic Center of Irving",
    isFreezed: false,
    isVerified: true,
    createdAt: "2023-04-04T19:23:15.538Z",
    updatedAt: "2024-10-10T23:33:43.288Z",
    isConfirmed: true,
  },
  {
    _id: "2",
    name: "Noor M",
    email: "noor@gmail.com",
    role: "musaliadmin",
    status: "active",
    masjid: "ICQC Masjid",
    isFreezed: true,
    isVerified: true,
    createdAt: "2023-04-04T19:23:15.538Z",
    updatedAt: "2024-10-10T23:33:43.288Z",
    isConfirmed: true,
  },

  {
    _id: "3",
    name: "Bibek",
    email: "bibek@gmail.com",
    role: "admin",
    status: "active",
    masjid: "",
    isFreezed: false,
    isVerified: false,
    createdAt: "2023-04-04T19:23:15.538Z",
    updatedAt: "2024-10-10T23:33:43.288Z",
    isConfirmed: true,
  },
  {
    _id: "4",
    name: "Aasif",
    email: "aasif@gmail.com",
    role: "superadmin",
    status: "active",
    masjid: "",
    isFreezed: true,
    isVerified: false,
    createdAt: "2023-04-04T19:23:15.538Z",
    updatedAt: "2024-10-10T23:33:43.288Z",
    isConfirmed: true,
  },
];

const dummyLogs: any = [
  {
    _id: "676ad288d25a809459d6d516",
    masjidId: "676462281e4a22285b62ade1",
    description: "Verify!",
    status: "approved",
    createdAt: "2024-12-24T15:26:00.756Z",
    updatedAt: "2024-12-24T15:26:00.756Z",
    userDetails: {
      name: "John Doe.",
      role: "superadmin",
      email: "prabhat.ranjan@msasoftware.us",
      __typename: "User",
    },
    __typename: "MasjidLedger",
  },
  {
    _id: "676ab892d25a809459d6d35e",
    masjidId: "676462281e4a22285b62ade1",
    description: "Freezed!",
    status: "frozen",
    createdAt: "2024-12-24T13:35:14.968Z",
    updatedAt: "2024-12-24T13:35:14.968Z",
    userDetails: {
      name: "John Doe.",
      role: "superadmin",
      email: "prabhat.ranjan@msasoftware.us",
      __typename: "User",
    },
    __typename: "MasjidLedger",
  },
];

const ADMIN_LEDGER_QUERY = gql`
  query UserLedgers($userId: String!) {
    userLedgers(userId: $userId) {
      _id
      userId
      description
      status
      adminId
      adminDetails {
        _id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const GET_ADMIN_BY_ID= gql `
query GetAdminById($id: String!) {
    getAdminById(id: $id) {
      _id
      name
      masjidDetails {
        _id
        masjidName
        location {
          coordinates
        }
        isAssigned
        isFreezed
        isVerified
        assignedUser {
          _id
        }
      }
      isAddedFromAdmin
      contact
      isApproved
      email
      role
      autoPrefillingTiming
      isVerified
      profilePhoto
      isVerified
      isFreezed
      createdAt
      updatedAt
      masjids
      
      }
      
      }
`;
export const FILTER_ADMINS_QUERY = gql`
  query filterAdmins($filterBy: String!, $filterValue: String!, $limit: Int!) {
    filterAdmins(
      filterBy: $filterBy
      filterValue: $filterValue
      limit: $limit
    ) {
      _id
      name
      masjidDetails {
        _id
        masjidName
        location {
          coordinates
        }
        isAssigned
        isFreezed
        isVerified
        assignedUser {
          _id
        }
      }
      isAddedFromAdmin
      contact
      isApproved
      email
      role
      autoPrefillingTiming
      isVerified
      profilePhoto
      isVerified
      isFreezed
      createdAt
      updatedAt
    }
  }
`;
export const useAdminLedgers = (userId: string, skip: boolean) => {
  const { loading, error, data, refetch } = useQuery(ADMIN_LEDGER_QUERY, {
    variables: { userId },
    skip: skip,
    fetchPolicy: "network-only",
  });

  return {
    loading: loading,
    error: error,
    data: data ? data.userLedgers : [],
    refetch: refetch,
  };
};

export const useGetAdminById = (adminId: string, skip: boolean=false) => {
  const { loading, error, data, refetch } = useQuery(GET_ADMIN_BY_ID, {
    variables: { id: adminId },
    skip: skip,
    fetchPolicy: "network-only",
  });
  console.log(data)
  return {
    loading: loading,
    error: error,
    data: data?.getAdminById ?? null,
    refetch,
  };
};

export const useInternalUsers = (skip: boolean) => {
  //   const { loading, error, data, refetch } = useQuery(GET_MASJID_BY_ID, {
  //     variables: { id: masjidId },
  //     skip: skip,
  //     fetchPolicy: "network-only",
  //   });

  const { loading, error, data, refetch } = useQuery(GetUsers, {
    variables: { limit: 10000, type: "internal" },
    skip: skip,
    fetchPolicy: "network-only",
  });
  return {
    data: data
      ? data.users.filter(
          (user: any) => user.role !== "musaliadmin" && user.role !== "subadmin"
        )
      : [],
    loading: loading,
    error: error,
    refetch: refetch,
  };
};

export const getFreezedAdmins = (skip: boolean) => {
  //   const { loading, error, data, refetch } = useQuery(GET_MASJID_BY_ID, {
  //     variables: { id: masjidId },
  //     skip: skip,
  //     fetchPolicy: "network-only",
  //   });

  const { loading, error, data, refetch } = useQuery(FILTER_ADMINS_QUERY, {
    variables: {
      limit: 10000,
      // page: 0,
      filterBy: "isFreezed",
      filterValue: "true",
    },
    skip: skip,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  return {
    data: data?.filterAdmins?.length > 0 ? data.filterAdmins : [],
    loading: loading,
    error: error,
    refetch: refetch,
  };
};

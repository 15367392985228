// import { GET_ALL_ADMIN } from '../../ActionType';
import * as api from "../../../APICalls/index";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";
import { Dispatch } from "redux";

export const FetchingAdmins = () => async (dispatch: Dispatch) => {
  try {
    const response = await api.getAllAdmin();

    if (response.status === 200) {
      dispatch({ type: "GET_ALL_ADMIN", payload: response.data.data });

      let result = {
        data: response.data,
        success: true,
      };

      return result;
    }
    return response.data;
  } catch (error) {
    handleSnackbar(
      true,
      "error",
      `Fetching Admin Failed: ${error.response.data.message}`,
      dispatch
    );
    return error.response.data;
  }
};

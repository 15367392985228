import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useExternalUsers } from "../../../graphql/Admins/query";
import {
  AdminStatus,
  getAdminStatus,
  getFormattedAdminStatus,
} from "../helpers";
import ExternalAdminStatushandler from "./ExternalAdminStatushandler";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import addAdmin from "../../../Photos/Newuiphotos/Admins/addAdmin.png";
import InternalAdminForm from "../InternalAdmins/InternalAdminForm";
import toast from "react-hot-toast";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { GET_MASJIDS } from "../../../graphql/queries";
import { AddingNewAdmin } from "../../../Redux/Actions/AdminActions/AddingNewAdmin";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { UpdateMasjidInAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import {
  UPDATE_USER_STATUS,
  USER_MASJID_ACTION,
} from "../../../graphql/Admins/mutation";
import ReasonConfirmation from "./ReasonConfirmation";
import { AddingNewAdminWithDescription } from "../../../Redux/Actions/AdminActions/AddingNewAdminWithDesc";
import CloseIcon from "@mui/icons-material/Close";
import customScrollarStyles from "../../Shared/CustomScrollBar.module.css";
import moment from "moment";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
  getFormattedMasjidStatus,
  getStatus,
} from "../../PageComponents/MasjidTable/Helper";
import {
  Admin,
  AdminInterFace,
  CellDataType,
  Masjid,
  tempAdmin,
} from "../../../../Types";
import ExternalTable from "./ExternalTable";
import MasjidStatusHandler from "../../PageComponents/MasjidTable/MasjidStatusHandler";

interface Cooldowns {
  [key: string]: number;
}
const ExternalAdminsTable = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForMasjid, setModalOpenForMasjid] = useState(false);
  const [logDetails, setLogDetails] = useState();

  const [searchText, setSearchText] = useState("");
  const [selectedAdminId, setSelectedAdminId] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState<AdminInterFace | null>(
    null
  );
  const [showAddForm, setShowAddForm] = useState(false);
  const [reason, setReason] = useState("");
  const [showReasonConfirmation, setShowReasonConfirmation] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [cooldowns, setCooldowns] = useState<Cooldowns | {}>({});
  const [selectedMasjid, setSelectedMasjid] = useState<Masjid | null>(null);

  useEffect(() => {
    if (!modalOpenForMasjid) setSelectedMasjid(null);
  }, [modalOpenForMasjid]);

  useEffect(() => {
    // Set up an interval that runs every second
    const timerId = setInterval(() => {
      // Decrement each active admin’s countdown
      setCooldowns((prev) => {
        const newCooldowns = {};

        // Loop over each adminId in prev
        Object.keys(prev).forEach((adminId: string) => {
          const secondsLeft = (prev as Cooldowns)[adminId];
          // If there's more than 1 second left, decrement.
          // If it hits 1 → 0, we simply omit that admin from newCooldowns
          if (secondsLeft > 1) {
            (newCooldowns as Cooldowns)[adminId] = secondsLeft - 1;
          }
        });

        return newCooldowns;
      });
    }, 1000);

    // Cleanup on unmount
    return () => clearInterval(timerId);
  }, []);

  const [tempAdmin, setTempAdmin] = useState<null | tempAdmin>(null);

  const dispatch = useAppThunkDispatch();
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleActionClick = useCallback(
    (action: string, details: AdminInterFace) => {
      setModalOpen(true);
      setSelectedAdminId(details._id);
      setSelectedAdmin(details);
    },
    []
  );
  const handleActionClickForMasjid = useCallback(
    (action: string, details: Masjid) => {
      setModalOpenForMasjid(true);
      setSelectedMasjid(details);
    },
    []
  );

  const [
    updateAdminMasjid,
    {
      data: updateAdminMasjidData,
      loading: updateAdminMasjidLoading,
      error: updateAdminMasjidError,
    },
  ] = useMutation(USER_MASJID_ACTION);
  const { data, loading, error, refetch } = useExternalUsers(false);

  const handleAddAdmin = async () => {
    setReason("");
    if (tempAdmin) {
      let updatedData = {
        name: tempAdmin.name,
        email: tempAdmin.email.toLowerCase(),
        role: tempAdmin.role,
        description: reason,
      };
      console.log(updatedData, tempAdmin.selectedMasjid, reason);

      try {
        const toastId = toast.loading("Adding Admin...");
        const result = await dispatch(
          AddingNewAdminWithDescription(updatedData)
        );
        if (!result._id) {
          console.log("error occured");
          toast.dismiss(toastId);
          return;
        } else if (tempAdmin.selectedMasjid) {
          const adminMasjidData = {
            userId: result._id,
            masjidId: tempAdmin.selectedMasjid,
            action: "assign",
          };
          updateAdminMasjid({ variables: adminMasjidData })
            .then((resultUpdateMasjid) => {
              if (!resultUpdateMasjid?.data?.userMasjidAction) {
                throw new Error("Error adding masjid to admin");
              } else {
                console.log("Added masjid to admin:", resultUpdateMasjid);

                const snackbarSuccessDetails = {
                  snackbarOpen: true,
                  snackbarType: "success",
                  snackbarMessage:
                    "Admin credentials have been sent to the admin",
                };
                toast.dismiss();
                dispatch(ChangeSnackbar(snackbarSuccessDetails));
                refetch();
              }
            })
            .catch((error) => {
              refetch();
              console.log("Error adding masjid to admin:", error);

              let errorMessage = "Error assigning masjid to admin.";
              if (error instanceof ApolloError && error.graphQLErrors.length) {
                errorMessage = error.graphQLErrors[0].message;
              }

              const snackbarFailureDetails = {
                snackbarOpen: true,
                snackbarType: "error",
                snackbarMessage: errorMessage,
              };
              toast.dismiss(toastId);
              dispatch(ChangeSnackbar(snackbarFailureDetails));
            });
        } else {
          const snackbarSuccessDetails = {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Admin credentials have been sent to the admin",
          };
          dispatch(ChangeSnackbar(snackbarSuccessDetails));
          refetch();
          toast.dismiss(toastId);
        }
      } catch (err) {
        console.error("Error adding admin:", error);
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: "Error adding admin",
        };
        toast.dismiss();
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    }
  };

  const filteredData: AdminInterFace[] | [] = data?.length
    ? data
        .filter((row: AdminInterFace) => {
          const searchTerm = searchText.trim().toLowerCase();
          const adminStatus = getFormattedAdminStatus(
            getAdminStatus(row?.isFreezed, row?.isVerified)
          ).toLowerCase();

          return (
            row.email?.toLowerCase().includes(searchTerm) ||
            row.name?.toLowerCase().includes(searchTerm) ||
            row.masjidDetails[0]?.masjidName
              .toLowerCase()
              .includes(searchTerm) ||
            roleRenamer(row.role).toLowerCase().includes(searchTerm) ||
            row.contact?.toLowerCase().includes(searchTerm) ||
            adminStatus?.includes(searchTerm)
          );
        })
        .sort((a: AdminInterFace, b: AdminInterFace) => {
          // Assign groups based on conditions
          const groupA = getGroupPriority(a);
          const groupB = getGroupPriority(b);

          // Compare group priority first
          if (groupA !== groupB) {
            return groupA - groupB;
          }

          // If in the same group, sort by createdAt
          return (
            new Date(b.createdAt as string).getTime() -
            new Date(a.createdAt as string).getTime()
          );
        })
    : [];
  // Helper function to assign group priority
  function getGroupPriority(item: AdminInterFace) {
    if (!item.isFreezed && (!item.isApproved || !item.isVerified)) {
      return 1; // Group 1: isFreezed = false, isApproved or isVerified = false
    }
    if (!item.isFreezed && item.isApproved && item.isVerified) {
      return 2; // Group 2: isFreezed = false, isApproved and isVerified = true
    }
    return 3; // Group 3: isFreezed = true
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 3,
      }}
    >
      {showReasonConfirmation && (
        <Dialog
          open={showReasonConfirmation}
          onClose={() => {
            setReason("");
            setShowReasonConfirmation(false);
            setShowAddForm(true);
          }}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: "22px",
            },
          }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              borderBottom: "1px solid #ccc",
              color: "#3D5347",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Reason
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowReasonConfirmation(false);
                setShowAddForm(true);
              }}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            className={customScrollarStyles["custom-scrollbar"]}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              overflowX: "hidden",
              minHeight: "400px",
              height: "100%",
            }}
          >
            <ReasonConfirmation
              onConfirm={(status: string) => {
                if (!reason) {
                  setReasonError(true);
                  return;
                }
                setShowReasonConfirmation(false);
                setShowAddForm(false);
                handleAddAdmin();
              }}
              reason={reason}
              setReason={setReason}
              reasonError={reasonError}
              setReasonError={setReasonError}
              isLoading={false}
              status={"approving"}
            />
          </DialogContent>
        </Dialog>
      )}
      <Card sx={{ mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchIcon sx={{ fontSize: "25px" }} />
                </IconButton>
              ),
            }}
            sx={{
              margin: "20px",
              width: "80%",
              borderRadius: "30px", // Add this line to increase the border radius
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow here
              borderColor: "#565656",
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px", // Ensure the input field's border radius is updated
              },
              "& .MuiOutlinedInput-notchedOutline": {
                // borderColor: "#565656",
              },
            }}
            variant="outlined"
          />

          <Button
            variant="outlined"
            sx={{
              color: "#1B8368",
              borderColor: "#1B8368",
              borderWidth: "2px",
              height: "fit-content",
              borderRadius: "20px",
              textTransform: "none",
              fontWeight: "bold",
              marginRight: "20px",
              textWrap: "nowrap",
              padding: "8px 12px",
              width: "auto",
              ":hover": {
                borderColor: "#1B8368",
                borderWidth: "2px",
              },
            }}
            onClick={() => {
              setSelectedAdmin(null);
              setShowAddForm(true);
            }}
          >
            <Box
              component="img"
              src={addAdmin}
              sx={{ width: "20px", marginRight: "8px" }}
            />
            Add New Admin
          </Button>
        </Box>
        <div
          style={{
            height: loading ? "650px" : "1200px",
            width: "100%",
            overflow: "auto",
          }}
        >
          <ExternalTable
            loading={loading}
            filteredData={filteredData}
            handleActionClick={handleActionClick}
            handleActionClickForMasjid={handleActionClickForMasjid}
          />
        </div>
      </Card>
      {modalOpen && (
        <ExternalAdminStatushandler
          cooldowns={cooldowns}
          setCooldowns={setCooldowns}
          selectedAdmin={selectedAdmin}
          setSelectedAdmin={setSelectedAdmin}
          open={modalOpen}
          onClose={() => {}}
          setOpen={setModalOpen}
          isLoading={false}
          refetch={refetch}
        />
      )}

      <InternalAdminForm
        open={showAddForm}
        onClose={() => {
          setShowAddForm(false);
          setTempAdmin(null);
        }}
        handleSubmit={(name, email, role, selectedMasjid) => {
          setShowReasonConfirmation(true);
          setShowAddForm(false);
          setTempAdmin({ name, email, role, selectedMasjid });
        }}
        adminType="external"
        existingDetails={tempAdmin}
      />
      <MasjidStatusHandler
        refetch={refetch}
        open={modalOpenForMasjid}
        setOpen={setModalOpenForMasjid}
        onConfirm={() => {}}
        selectedMasjid={selectedMasjid as Masjid}
        setSelectedMasjid={setSelectedMasjid}
      />
    </Box>
  );
};

export default ExternalAdminsTable;

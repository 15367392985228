import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./AdminProfile.scss";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { FreezeAdmin } from "../../../Redux/Actions/AdminActions/FreezingAdmin";
import { FetchingAdminById } from "../../../Redux/Actions/AdminActions/FetchingAdminByName";
import { UpdatingAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminById";
import Box from "@mui/material/Box";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { ReSendingReinvitation } from "../../../Redux/Actions/AuthActions/ResendingInvitation";
import { MdCancel, MdCheck, MdEdit } from "react-icons/md";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { UpdateMasjidInAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById";
import { getMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjids";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";
import {
  useAppDispatch,
  useAppSelector,
  useAppThunkDispatch,
} from "../../../Redux/hooks";
import { AdminInterFace } from "../../../Components/FeedComponents/Feed/Feed";
import { Masjid } from "../../../../Types";
import { Autocomplete } from "@mui/material";
import toast from "react-hot-toast";
import { mailChecker } from "../../../helpers/HelperFunction";
import MasjidsAutocomplete from "../../Shared/MasjidsAutocomplete/MasjidsAutocomplete";
import { DeleteMasjidInAdminById } from "../../../Redux/Actions/AdminActions/DeleteMasjidInAdminById";
import PageContainer from "../../Shared/PageContainer/PageContainer";
import { roleRenamer } from "../../../helpers/RoleRenamer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(name: Masjid, masjidName: any, theme: any) {
  return {
    fontWeight:
      masjidName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AdminProfile = () => {
  let navigate = useNavigate();
  const adminString = localStorage.getItem("admin");
  const admin: AdminInterFace = adminString ? JSON.parse(adminString) : null;
  const { adminId } = useParams();
  const dispatch = useAppThunkDispatch();
  interface AdminType {
    _id: string;
    adminName: string;
    email: string;
    name: string;
    role: string;
    isVerified: boolean;
    updatedAt: string;
    createdAt: string;
  }

  const [Admin, setAdmin] = useState<AdminType>();
  const sidebarState = useAppSelector((state) => state.sidebarState);
  //com-3
  // let AdminNameRef = useRef(Admin ? Admin.adminName : admin?.adminName);
  // let AdminEmailRef = useRef(Admin ? Admin?.email : admin?.email);
  const AdminNameRef = useRef<HTMLInputElement | null>(null);
  const [role, setRole] = useState("");
  let AdminEmailRef = useRef<HTMLInputElement | null>(null);
  const masjids = useAppSelector((state) => state.masjids);
  const [OpenEditModal, setOpenEditModal] = useState(false);
  const [isFetching, setisFetching] = useState(false);
  const theme = useTheme();
  const [masjidName, setMasjidName] = React.useState([]);
  const [isFreezing, setisFreezing] = useState(false);
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [SendInvitationModal, setSendInvitationModal] = useState(false);
  const [AdminMasjid, setAdminMasjid] = useState("");
  const [EditingAdminMasjid, setEditingAdminMasjid] = useState(false);
  const [NewmasjidName, setNewMasjidName] = useState("");
  const [selectedMasjidId, setId] = useState("");

  const handleChange = (event: any) => {
    setId(event?._id);
    setNewMasjidName(event?.masjidName);
  };

  useEffect(() => {
    setRole(Admin?.role);
  }, [Admin]);

  useEffect(() => {
    setisFetching(true);
    if (adminId !== undefined) {
      const response = dispatch(FetchingAdminById(adminId));
      response.then(function (result: any) {
        if (result.success) {
          setisFetching(false);
          if (result.data?.masjidDetails?.length > 0) {
            let masjid = masjids.filter(
              (item) => item._id === result.data.masjidDetails[0]
            );
            setAdminMasjid(masjid?.masjidName);
          }
          setAdmin(result.data);
        } else {
          const snackbarFailureDetails = {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: result.message
              ? "Failed to Load Admin Details : " + result.message
              : "Failed to Load Masjid Details : Internet or Server Issue ",
          };
          setisFetching(false);
          setMasjidName([]);
          dispatch(ChangeSnackbar(snackbarFailureDetails));
        }
      });
    }
  }, [masjids, adminId]);

  const handleModalUpdate = () => {
    setOpenEditModal(true);
  };

  const handleModalUpdateClose = () => {
    setOpenEditModal(false);
  };

  const handleAdmin = () => {
    const email = AdminEmailRef.current?.value ?? "";

    if (!mailChecker(email)) return;

    let updatedData = {
      name: AdminNameRef.current?.value ?? "",
      email,
      role,
    };
    const response = dispatch(UpdatingAdminById(updatedData, Admin?._id ?? ""));
    response.then(function (result: any) {
      if (result.success) {
        setAdmin(result.data);
        setOpenEditModal(false);
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: result.message
            ? "Failed to Load Admin Details : " + result.message
            : "Failed to Load Masjid Details : Internet or Server Issue ",
        };
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  };

  const handleFreeze = () => {
    setFreezeModalOpen(false);
  };

  const handleFreezeAdmin = async () => {
    setisFreezing(true);
    let formData = {
      userId: Admin?._id,
      isFreeze: true,
    };

    const isUserDeleted = await dispatch(
      DeleteMasjidInAdminById({ user: Admin?._id ?? "" })
    );

    if (isUserDeleted.success) {
      const response = await dispatch(FreezeAdmin(formData));
      if (response.success) {
        const snackbarSuccessDetails = {
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: "Freezed The Admin Successfully",
        };
        dispatch(ChangeSnackbar(snackbarSuccessDetails));

        setisFreezing(false);
        setFreezeModalOpen(false);
        navigate("/freezeAdmins");
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            "Couldn't update the Admin Profile: " + response.message,
        };
        setisFreezing(false);
        setFreezeModalOpen(false);
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    } else {
      // Handle the case where the first dispatch does not return true
      const snackbarFailureDetails = {
        snackbarOpen: true,
        snackbarType: "error",
        snackbarMessage: "Failed to perform the first dispatch",
      };
      setisFreezing(false);
      setFreezeModalOpen(false);
      dispatch(ChangeSnackbar(snackbarFailureDetails));
    }
  };

  const handleSendInvitation = () => {
    if (Admin?.email) {
      const response = dispatch(ReSendingReinvitation(Admin.email));
      response.then(function (result: any) {
        if (result.success) {
          const snackbarSuccessDetails = {
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Sent the email successfully",
          };
          dispatch(ChangeSnackbar(snackbarSuccessDetails));
          setSendInvitationModal(false);
        } else {
          const snackbarFailureDetails = {
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: "Failed to send the email",
          };
          dispatch(ChangeSnackbar(snackbarFailureDetails));
          setSendInvitationModal(false);
        }
      });
    }
  };

  const handleUpdationAdmin = () => {
    let UploadData = {
      user: Admin?._id,
      masjidId: selectedMasjidId,
    };
    const response = dispatch(UpdateMasjidInAdminById(UploadData));
    response.then(function (result: any) {
      if (result.success) {
        setEditingAdminMasjid(!EditingAdminMasjid);
        handleSnackbar(
          true,
          "success",
          "Updated Masjid In Admin Successfully",
          dispatch
        );
      } else {
        const snackbarFailureDetails = {
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: result.message
            ? "Failed to Update Admin Details : " + result.message
            : "Failed to Update Masjid Details : Internet or Server Issue ",
        };
        setEditingAdminMasjid(!EditingAdminMasjid);
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    });
  };

  return (
    <PageContainer title="Admin Profile">
      <div className="single-contain-admin-profile">
        {isFetching && (
          <>
            <span className="LoadingMessage">
              Fetching The Admin Data{" "}
              <CircularProgress
                size="20px"
                style={{ marginLeft: "10px", marginTop: "5px" }}
              />
            </span>
          </>
        )}

        <div className="top">
          <Dialog
            open={FreezeModalOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to freeze the Admin ?`}
            </DialogTitle>
            <DialogActions style={{ justifyContent: "space-around" }}>
              <Button
                onClick={handleFreeze}
                variant="outlined"
                disabled={isFreezing}
              >
                No
              </Button>
              <Button onClick={handleFreezeAdmin} variant="outlined">
                {" "}
                {isFreezing ? (
                  <CircularProgress size="15px" />
                ) : (
                  <span> Yes </span>
                )}{" "}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={SendInvitationModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure you want to send the invitation again?`}
            </DialogTitle>
            <DialogActions style={{ justifyContent: "space-around" }}>
              <Button
                onClick={(e) => {
                  setSendInvitationModal(false);
                }}
                variant="outlined"
                disabled={isFreezing}
              >
                No
              </Button>
              <Button onClick={handleSendInvitation} variant="outlined">
                {" "}
                {isFreezing ? (
                  <CircularProgress size="15px" />
                ) : (
                  <span> Yes </span>
                )}{" "}
              </Button>
            </DialogActions>
          </Dialog>

          <div className="admin-left">
            {admin?.role === "superadmin" && (
              <>
                <Button
                  onClick={(e) => setFreezeModalOpen(true)}
                  style={{
                    border: "1px solid red",
                    color: "red",
                    height: "20px",
                    fontSize: "10px",
                    marginLeft: "3rem",
                    marginBottom: "10px",
                    cursor: "Pointer",
                  }}
                >
                  Freeze Admin
                </Button>
                {!Admin?.isVerified && (
                  <Button
                    onClick={(e) => setSendInvitationModal(true)}
                    style={{
                      border: "1px solid green",
                      color: "green",
                      height: "20px",
                      fontSize: "10px",
                      marginLeft: "3rem",
                      marginBottom: "10px",
                      cursor: "Pointer",
                    }}
                  >
                    Send Invitation
                  </Button>
                )}
              </>
            )}
            <div className="item">
              <div className="details">
                {Admin?._id ? (
                  <>
                    <h1 className="itemTitle">
                      {Admin && Admin?.name}
                      {(admin._id === Admin?._id ||
                        admin?.role === "superadmin") && (
                        <Button
                          variant="outlined"
                          style={{
                            maxWidth: "100px",
                            maxHeight: "25px",
                            fontSize: "11px",
                            fontWeight: "bold",
                            marginLeft: "1rem",
                          }}
                          onClick={handleModalUpdate}
                          className="btn-edit"
                        >
                          Edit Admin
                        </Button>
                      )}
                    </h1>
                    <div className="detailItem">
                      <span className="itemKey">Email : </span>
                      <span className="itemValue">
                        {Admin && Admin?.email ? (
                          Admin?.email
                        ) : (
                          <span className="itemKey2">
                            {" "}
                            {"No Email available for the Admin"}
                          </span>
                        )}
                      </span>
                    </div>
                    {!EditingAdminMasjid &&
                    (Admin.role === "subadmin" ||
                      Admin.role === "musaliadmin") ? (
                      <>
                        <div className="detailItem">
                          <span className="itemKey">Masjid : </span>
                          <span className="itemValue">
                            {Admin && AdminMasjid?.length > 0 ? (
                              AdminMasjid
                            ) : (
                              <span className="itemKey2">
                                {" "}
                                {"No Masjid available for the Admin"}
                              </span>
                            )}
                          </span>
                          <MdEdit
                            style={{
                              marginLeft: "10px",
                              cursor: "Pointer",
                            }}
                            onClick={(e) => {
                              setEditingAdminMasjid(!EditingAdminMasjid);
                            }}
                          />
                        </div>
                      </>
                    ) : null}
                    {EditingAdminMasjid && (
                      <>
                        <div
                          style={{
                            width: "70%",
                            marginBottom: "10px",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <MasjidsAutocomplete handleChange={handleChange} />
                          <MdCheck
                            style={{
                              marginLeft: "10px",
                              cursor: "Pointer",
                              fontSize: "200%",
                            }}
                            onClick={(e) => {
                              setAdminMasjid(NewmasjidName);
                              handleUpdationAdmin();
                            }}
                          />
                          <MdCancel
                            style={{
                              marginLeft: "10px",
                              cursor: "Pointer",
                              fontSize: "200%",
                            }}
                            onClick={(e) => {
                              setEditingAdminMasjid(!EditingAdminMasjid);
                            }}
                          />
                        </div>
                      </>
                    )}

                    <div className="detailItem">
                      <span className="itemKey">Role :</span>
                      <span className="itemValue">
                        {Admin && roleRenamer(Admin?.role)}
                        {/* 
                            //com-3

                          {Admin && Admin?.role : Admin?.role} */}
                      </span>
                    </div>
                    <Dialog open={OpenEditModal}>
                      <DialogTitle>Edit Admin</DialogTitle>
                      <DialogContent>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Admin Name"
                          sx={{ marginBottom: 3 }}
                          type="text"
                          fullWidth
                          defaultValue={Admin?.name}
                          inputRef={AdminNameRef}
                          variant="standard"
                        />
                        <TextField
                          id="outlined-required"
                          variant="standard"
                          label="Admin Email"
                          fullWidth
                          defaultValue={Admin?.email}
                          inputRef={AdminEmailRef}
                          type="email"
                          sx={{ marginBottom: 3 }}
                        />
                      </DialogContent>
                      <FormControl style={{ width: "90%", margin: "0px auto" }}>
                        <InputLabel id="demo-simple-select-label" shrink>
                          New Role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Admin Role"
                          value={role}
                          onChange={(e: SelectChangeEvent<string>) =>
                            setRole(e.target.value)
                          }
                        >
                          <MenuItem value={"admin"}>Data Entry</MenuItem>
                          <MenuItem value={"subadmin"}>Masjid Admin</MenuItem>
                          <MenuItem value={"musaliadmin"}>
                            Musali Admin
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <DialogActions style={{ justifyContent: "space-around" }}>
                        <Button
                          onClick={handleModalUpdateClose}
                          style={{ color: "grey" }}
                        >
                          Cancel
                        </Button>
                        <Button onClick={handleAdmin}> Update </Button>
                      </DialogActions>
                    </Dialog>

                    <div className="detailItem">
                      <b>
                        <span className="itemKey">Created At :</span>
                      </b>
                      <span className="itemValue">
                        {Admin ? (
                          moment(Admin?.createdAt).format("DD/MM/YYYY")
                        ) : (
                          <span className="itemKey2">
                            {" "}
                            {"Last Update is not Available"}{" "}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="detailItem">
                      <b>
                        <span className="itemKey">Last Updated At :</span>
                      </b>
                      <span className="itemValue">
                        {Admin ? (
                          moment(Admin?.updatedAt).format("DD/MM/YYYY")
                        ) : (
                          <span className="itemKey2"> </span>
                        )}
                      </span>
                    </div>
                    <div className="detialItem"></div>
                  </>
                ) : (
                  <>
                    <div className="skeleton-placeholder-title" />
                    <div className="skeleton-placeholder-title2" />
                    <div className="skeleton-placeholder-title2" />
                    <div className="skeleton-placeholder" />
                    <div className="skeleton-placeholder" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default AdminProfile;

import React from "react";
import { List, Paper } from "@mui/material";
import Message from "../Message/Message";
import { Message as MessageType } from "../../../../Types";

const ChatWindow: React.FC<{ messages: MessageType[] }> = ({ messages }) => {
  return (
    <Paper
      elevation={3}
      style={{
        height: "400px",
        overflowY: "auto",
        padding: "10px",
        backgroundColor: "#F2F5FC",
      }}
    >
      {/* <div className="random">ebgyehdenfbgev</div> */}
      <List
        style={{
          padding: "0",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {messages?.map((msg, index) => (
          <Message
            key={index}
            message={msg.body}
            createdAt={msg.createdAt}
            author={msg?.senderUser?.name?.trim()?.split(" ")[0] || ""}
          />
        ))}
      </List>
    </Paper>
  );
};

export default ChatWindow;

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const MASJID_LEDGER_QUERY = gql`
  query MasjidLedgers($masjidId: String!) {
    masjidLedgers(masjidId: $masjidId) {
      _id
      masjidId
      description
      status
      createdAt
      updatedAt
      userDetails {
        name
        role
        email
      }
    }
  }
`;

export const useMasjidLedgers = (masjidId: string, skip: boolean) => {
  const { loading, error, data, refetch } = useQuery(MASJID_LEDGER_QUERY, {
    variables: { masjidId },
    skip: skip,
    fetchPolicy: "network-only",
  });
  return {
    loading,
    error,
    data: data?.masjidLedgers ?? [],
    refetch,
  };
};

export const GET_MASJID_BY_ID = gql`
  query GetMasjidById($id: String!) {
    getMasjidById(id: $id) {
      subscribers
      followers
      _id
      masjidName
      masjidProfilePhoto
      address
      contact
      updatedAt
      externalLinks {
        name
        url
      }
      isFreezed
      isVerified
      isAssigned
      updatedBy {
        name
        isUnofficial
        role
      }
      assignedUser {
        _id
        name
        role
        contact
        email
        isApproved
        isAddedFromAdmin
      }
      location {
        coordinates
      }
      status
      createdAt
    }
  }
`;

export const GET_MASJID_BY_ID_FOR_MASJID_DETAILS= gql`
  query GetMasjidById($id: String!) {
    getMasjidById(id: $id) {
        _id
        masjidName
        location {
          coordinates
        }
        isAssigned
        isFreezed
        isVerified
        assignedUser {
          _id
        }
    }
}`;
export const useGetMasjidById = (masjidId: string, skip: boolean) => {
  const { loading, error, data, refetch } = useQuery(GET_MASJID_BY_ID, {
    variables: { id: masjidId },
    skip: skip,
    fetchPolicy: "network-only",
  });
  return {
    loading,
    error,
    data: data?.getMasjidById ?? null,
    refetch,
  };
};
export const SEARCH_MASJIDS = gql`
  query SearchMasjids(
    $q: String
    $lat: Float
    $lng: Float
    $page: Int = 1
    $limit: Int = 10
  ) {
    searchMasjids(q: $q, lat: $lat, lng: $lng, page: $page, limit: $limit) {
      _id
      masjidName
      address
      contact
      isAssigned
      assignedUser {
        _id
        name
        role
      }
      isVerified
      updatedBy {
        name
        isUnofficial
        role
      }
    }
  }
`;
export const SEARCH_MASJIDS_FOR_MASJID_TABLE = gql`
  query SearchMasjids(
    $q: String
    $lat: Float
    $lng: Float
    $page: Int = 1
    $limit: Int = 10
  ) {
    searchMasjids(q: $q, lat: $lat, lng: $lng, page: $page, limit: $limit) {
      _id
      subscribers
      followers
      masjidName
      masjidProfilePhoto
      address
      contact
      updatedAt
      externalLinks {
        name
        url
      }
      isFreezed
      isVerified
      isAssigned
      updatedBy {
        name
        role
      }
      createdAt
      assignedUser {
        _id
        name
        role
        contact
        email
      }
      status
    }
  }
`;

export const useSearchMasjids = (
  q?: string,
  page: number = 1,
  limit: number = 20,
  lat?: number,
  lng?: number,
) => {
  const { data, loading, error,refetch} = useQuery(SEARCH_MASJIDS, {
    variables: { q, lat, lng, page, limit, },
    notifyOnNetworkStatusChange: true,
    skip: !q,
  });

  return {
    masjids: data?.searchMasjids,
    loading,
    error,
    refetch
  };
};
export const useSearchMasjidsForMasjidTable = (
  q?: string,
  page: number = 1,
  limit: number = 10,
  lat?: number,
  lng?: number,
) => {
  const { data, loading, error,refetch} = useQuery(SEARCH_MASJIDS_FOR_MASJID_TABLE, {
    variables: { q, lat, lng, page, limit, },
    notifyOnNetworkStatusChange: true,
    skip: !q,
  });

  return {
    masjids: data?.searchMasjids,
    loading,
    error,
    refetch
  };
};

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  IconButton, // 1) Import IconButton
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // 2) Import Copy Icon
import moment from "moment";
import toast from "react-hot-toast";

import { AdminStatus, getAdminStatus } from "../helpers";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import EditIcon from "../../../Photos/Newuiphotos/Icons/el_edit.png";
import Dropdown from "../InternalAdmins/customDropdown/CustomDropdown";
import ChangeIcon from "../../../Photos/Newuiphotos/Admins/exchange.png";
import { Masjid } from "../../../../Types";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { ChangeSnackbar } from "../../../Redux/Actions/SnackbarActions/SnackbarActions";
import { UpdateMasjidInAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById";
import ReasonConfirmation from "./ReasonConfirmation";
import { ReSendingReinvitation } from "../../../Redux/Actions/AuthActions/ResendingInvitation";
import { DeleteMasjidInAdminById } from "../../../Redux/Actions/AdminActions/DeleteMasjidInAdminById";
import EmailFilledIcon from "../../../Photos/Newuiphotos/Admins/EmailFilledIcon.png";
import StatusLogs from "../StatusLogs/StatusLogs";
import { useSearchMasjids } from "../../../graphql/Masjids/query";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import SearchDropdownChange from "./SearchMasjidChange";
import { USER_MASJID_ACTION } from "../../../graphql/Admins/mutation";
import { ApolloError, useMutation } from "@apollo/client";

const ValueFieldStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#1B8368",
  fontWeight: "bold",
};
const getValueFieldStyles = (value: any) => {
  if (value) {
    return ValueFieldStyles;
  } else {
    return { ...ValueFieldStyles, color: "#FF4949" };
  }
};

interface ExternalAdminHandler2Props {
  cooldowns: { [key: string]: number };
  setCooldowns: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
  isLoading: boolean;
  showReasonConfirmation: boolean;
  setShowReasonConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateAdminStatus: (status: string) => void;
  adminStatus: string;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reasonError: boolean;
  setReasonError: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAdmin: any;
  showLogs: boolean;
  setShowLogs: React.Dispatch<React.SetStateAction<boolean>>;
  logs: any;
  refetch: () => void;
  setOpen: (open: boolean) => void;
  setSkipfetchAdmin: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExternalAdminHandler2 = ({
  cooldowns,
  setCooldowns,
  isLoading,
  showReasonConfirmation,
  setShowReasonConfirmation,
  handleUpdateAdminStatus,
  adminStatus,
  reason,
  setReason,
  reasonError,
  setReasonError,
  selectedAdmin,
  showLogs,
  setShowLogs,
  logs,
  refetch,
  setOpen,
  setSkipfetchAdmin,
}: ExternalAdminHandler2Props) => {
  const dispatch = useAppThunkDispatch();
  const [freezeModalOpen, setFreezeModalOpen] = useState(false);
  const [isChangingMasjid, setIsChangingMasjid] = useState(false);
  const [selectedMasjid, setSelectedMasjid] = useState<Masjid | null>(null);
  const [isMasjidChanged, setIsMasjidChanged] = useState(false);
  const [sendInvitationModal, setSendInvitationModal] = useState(false);

  const [masjidsList, setMasjidsList] = useState<Masjid[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    masjids,
    loading,
    error,
    refetch: masjidRefetch,
  } = useSearchMasjids(searchQuery);

  const [
    updateAdminMasjid,
    {
      data: updateAdminMasjidData,
      loading: updateAdminMasjidLoading,
      error: updateAdminMasjidError,
    },
  ] = useMutation(USER_MASJID_ACTION);

  const getCooldownForAdmin = (adminId: string) => cooldowns[adminId] || 0;

  // ------------------- COPY HELPER -------------------
  const handleCopy = (text: string | undefined) => {
    if (!text) return;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy!");
      });
  };
  // ---------------------------------------------------

  // Search/Fetch Masjid
  useEffect(() => {
    if (!loading && !error) {
      if (masjids?.length > 0) {
        setMasjidsList(masjids);
      } else {
        setMasjidsList([]);
      }
    } else if (!loading && error) {
      setMasjidsList([]);
    }
  }, [masjids, loading, error]);

  useEffect(() => {
    setMasjidsList([]);
    if (searchQuery) {
      masjidRefetch();
    }
  }, [searchQuery, masjidRefetch]);

  const debouncedSearchMasjids = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  const handleSearchMasjid = (query: string) => {
    debouncedSearchMasjids(query);
  };

  const handleMasjidSelection = (masjid: Masjid) => {
    setSelectedMasjid(masjid);
    if (
      (!selectedAdmin?.masjidDetails?.[0]?.masjidId && masjid) ||
      masjid?._id !== selectedAdmin?.masjidDetails?.[0]?._id
    ) {
      setIsMasjidChanged(true);
    } else {
      setIsMasjidChanged(false);
    }
  };
  const handleSubmitMasjid = async () => {
    // Only do something if the masjid changed and it's different from the existing one
    if (
      !isMasjidChanged ||
      selectedMasjid?._id === selectedAdmin?.masjidDetails?.[0]?._id
    ) {
      setIsChangingMasjid(false);
      return;
    }

    const toastId = toast.loading("Updating Masjid...");

    try {
      // Decide whether we're assigning or unassigning the masjid
      const action = selectedMasjid ? "assign" : "unassign";
      // If unassigning, masjidId should come from the admin’s current masjid
      const masjidId =
        selectedMasjid?._id || selectedAdmin?.masjidDetails?.[0]?._id;

      const variables = {
        userId: selectedAdmin._id,
        masjidId,
        action,
      };

      const { data, errors } = await updateAdminMasjid({
        variables,
        // errorPolicy: "all",
      });

      // Check for a successful response
      if (errors && errors.length > 0) {
        throw new ApolloError({ graphQLErrors: errors });
      }

      // 2) If server signals an error in 'data'
      // For example, userMasjidAction might have success: false
      if (!data?.userMasjidAction) {
        throw new Error("No userMasjidAction was returned from the server.");
      }

      // Success handling
      toast.dismiss(toastId);
      toast.success("Masjid Updated Successfully");
      setSkipfetchAdmin(false);
      refetch();
    } catch (error) {
      // Dismiss the loading toast
      toast.dismiss(toastId);
      setIsMasjidChanged(false);
      setSelectedMasjid(null);

      // Default fallback
      let errorMessage = "Error Updating Masjid";

      // If it's an ApolloError, check for graphQLErrors
      if (error instanceof ApolloError && error.graphQLErrors.length) {
        errorMessage = error.graphQLErrors[0].message;
      }
      // Otherwise, see if it's a standard JS error
      else if (error instanceof Error) {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
      console.error(error);
    } finally {
      // Always stop the loading indicator for changing masjid
      setIsChangingMasjid(false);
    }
  };
  const handleCancelMasjid = () => {
    setIsMasjidChanged(false);
    setIsChangingMasjid(false);
    setSelectedMasjid(selectedAdmin?.masjidDetails?.[0] ?? null);
  };
  const handleSendInvitation = () => {
    if (selectedAdmin?.email) {
      toast.loading("Sending Invitation...");
      const response = dispatch(ReSendingReinvitation(selectedAdmin.email));
      response.then(function (result: any) {
        if (result.success) {
          toast.dismiss();
          dispatch(
            ChangeSnackbar({
              snackbarOpen: true,
              snackbarType: "success",
              snackbarMessage: "Sent the email successfully",
            })
          );
          setSendInvitationModal(false);
          setCooldowns((prev) => {
            const newCooldowns = { ...prev };
            newCooldowns[selectedAdmin._id] = 30; // 30 sec cooldown
            return newCooldowns;
          });
        } else {
          toast.dismiss();
          dispatch(
            ChangeSnackbar({
              snackbarOpen: true,
              snackbarType: "error",
              snackbarMessage: "Failed to send the email",
            })
          );
          setSendInvitationModal(false);
        }
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Dialog
        open={sendInvitationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to send an invitation again?"}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={() => {
              setSendInvitationModal(false);
            }}
            variant="outlined"
          >
            No
          </Button>
          <Button onClick={handleSendInvitation} variant="outlined">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading ? (
        <CircularProgress />
      ) : showReasonConfirmation ? (
        <ReasonConfirmation
          onConfirm={(status: string) => {
            if (!reason) {
              setReasonError(true);
              return;
            }
            handleUpdateAdminStatus(status);
          }}
          reason={reason}
          setReason={setReason}
          reasonError={reasonError}
          setReasonError={setReasonError}
          isLoading={isLoading}
          status={
            adminStatus === AdminStatus.DEACTIVE ? "approving" : "freezing"
          }
        />
      ) : showLogs ? (
        <StatusLogs logs={logs} feature={"Masjid Assignment"} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "auto",
          }}
        >
          <Box>
            {/* ---------- Status Section if Rejected/Freezed ---------- */}
            {getAdminStatus(
              selectedAdmin?.isFreezed,
              selectedAdmin?.isVerified
            ) === "rejected" && (
              <>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Status
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography variant="body2" sx={getValueFieldStyles("")}>
                    Freezed
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => handleCopy("Freezed")}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Box>
              </>
            )}

            {/* ---------- Role ---------- */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Role
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(selectedAdmin?.role)}
                  >
                    {roleRenamer(selectedAdmin?.role ?? "")}
                  </Typography>
                  {selectedAdmin?.role && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopy(roleRenamer(selectedAdmin?.role))
                      }
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>

            {/* ---------- Masjid (for non-admin/superadmin) ---------- */}
            {selectedAdmin?.role !== "admin" &&
              selectedAdmin?.role !== "superadmin" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "75%" }}>
                      <Typography variant="subtitle2" sx={{ mt: 1 }}>
                        Assigned Masjid
                      </Typography>
                      {!isChangingMasjid && (
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              ...getValueFieldStyles(
                                Array.isArray(selectedAdmin?.masjidDetails) &&
                                  selectedAdmin?.masjidDetails?.length > 0
                                  ? selectedAdmin?.masjidDetails[0].masjidName
                                  : ""
                              ),
                              ...(selectedAdmin?.masjidDetails?.length > 0 &&
                                !selectedAdmin?.isApproved &&
                                !selectedAdmin.isAddedFromAdmin && {
                                  color: "#F7A400",
                                }),
                            }}
                          >
                            {selectedAdmin?.masjidDetails?.length > 0
                              ? !selectedAdmin.isApproved &&
                                !selectedAdmin.isAddedFromAdmin
                                ? `Pending Assignment (${selectedAdmin?.masjidDetails[0].masjidName})`
                                : selectedAdmin?.masjidDetails[0].masjidName
                              : "Not Assigned"}
                          </Typography>

                          {/* Copy Icon for Masjid Name (if assigned) */}
                          {selectedAdmin?.masjidDetails?.length > 0 &&
                            selectedAdmin?.masjidDetails[0]?.masjidName && (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleCopy(
                                    selectedAdmin?.masjidDetails[0]?.masjidName
                                  )
                                }
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            )}
                        </Box>
                      )}
                    </Box>

                    {/* Change Masjid Button */}
                    {adminStatus !== AdminStatus.DEACTIVE &&
                      !isChangingMasjid && (
                        <Button
                          variant="contained"
                          sx={{
                            width: "105px",
                            borderRadius: "12px",
                            bgcolor: "#1B8368",
                            "&:hover": { bgcolor: "#1B8368" },
                            textTransform: "none",
                            padding: "3px",
                          }}
                          onClick={() => {
                            setIsChangingMasjid(true);
                          }}
                        >
                          Change
                          <Box
                            component={"img"}
                            src={ChangeIcon}
                            sx={{ width: "20px", marginLeft: "5px" }}
                          />
                        </Button>
                      )}
                  </Box>

                  {/* Change Masjid Dropdown */}
                  {isChangingMasjid && masjidsList && (
                    <Box mt={2}>
                      <Dropdown
                        loadingOptions={loading}
                        label={
                          Array.isArray(selectedAdmin?.masjidDetails) &&
                          selectedAdmin?.masjidDetails?.length > 0
                            ? selectedAdmin?.masjidDetails[0]?.masjidName
                            : "Select a Masjid"
                        }
                        options={masjidsList?.length > 0 ? masjidsList : []}
                        onSelect={handleMasjidSelection}
                        error={false}
                        selectedValue={
                          selectedMasjid
                            ? selectedMasjid
                            : Array.isArray(selectedAdmin?.masjidDetails) &&
                              selectedAdmin.masjidDetails.length > 0
                            ? selectedAdmin?.masjidDetails[0]._id
                            : ""
                        }
                        onInputChange={(query: string) => {
                          handleSearchMasjid(query);
                        }}
                        isMasjidChanged={isMasjidChanged}
                        handleSubmitMasjid={handleSubmitMasjid}
                        handleCancelMasjid={handleCancelMasjid}
                      ></Dropdown>
                    </Box>
                  )}
                </>
              )}

            {/* ---------- Full Name ---------- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Full Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.name)}
              >
                {selectedAdmin?.name ?? ""}
              </Typography>
              {selectedAdmin?.name && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(selectedAdmin?.name)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            {/* ---------- Email Address ---------- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Email Address
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.email)}
              >
                {selectedAdmin?.email ?? ""}
              </Typography>
              {selectedAdmin?.email && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(selectedAdmin?.email)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            {/* ---------- Created/Updated Timestamps ---------- */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 2, textTransform: "capitalize" }}
                >
                  Created At
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(selectedAdmin?.createdAt)}
                  >
                    {selectedAdmin?.createdAt
                      ? moment
                          .utc(selectedAdmin.createdAt)
                          .local()
                          .format("DD MMM, YYYY | hh:mm A")
                      : "Not Available"}
                  </Typography>
                  {/* {selectedAdmin?.createdAt && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopy(
                          moment
                            .utc(selectedAdmin.createdAt)
                            .local()
                            .format("DD MMM, YYYY | hh:mm A")
                        )
                      }
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  )} */}
                </Box>
              </Box>

              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 2, textTransform: "capitalize" }}
                >
                  Last Updated At
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(selectedAdmin?.updatedAt)}
                  >
                    {selectedAdmin?.updatedAt
                      ? moment
                          .utc(selectedAdmin.updatedAt)
                          .local()
                          .format("DD MMM, YYYY | hh:mm A")
                      : "Not Available"}
                  </Typography>
                  {/* {selectedAdmin?.updatedAt && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        handleCopy(
                          moment
                            .utc(selectedAdmin.updatedAt)
                            .local()
                            .format("DD MMM, YYYY | hh:mm A")
                        )
                      }
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  )} */}
                </Box>
              </Box>
            </Box>
          </Box>

          {/* ---------- Action Buttons & Logs ---------- */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "12px",
              mt: 3,
              gap: 2,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              {adminStatus === AdminStatus.ACTIVE ||
              adminStatus === AdminStatus.UNVERIFIED ? (
                <Button
                  variant="contained"
                  sx={{
                    width: "200px",
                    borderRadius: "20px",
                    bgcolor: "#FF2D30",
                    "&:hover": { bgcolor: "#FF2D30" },
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setShowReasonConfirmation(true);
                  }}
                >
                  Freeze Admin
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    width: "200px",
                    borderRadius: "20px",
                    bgcolor: "#00D23B",
                    "&:hover": { bgcolor: "#00D23B" },
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setShowReasonConfirmation(true);
                  }}
                >
                  Unfreeze Admin
                </Button>
              )}
              {selectedAdmin &&
                !selectedAdmin.isVerified &&
                adminStatus !== AdminStatus.DEACTIVE && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "200px",
                      borderRadius: "20px",
                      bgcolor: "#1B8368",
                      "&:hover": { bgcolor: "#1B8368" },
                      textTransform: "none",
                      "&.Mui-disabled": {
                        cursor: "not-allowed !important",
                        color: "#686868",
                      },
                    }}
                    onClick={() => {
                      setSendInvitationModal(true);
                    }}
                    disabled={getCooldownForAdmin(selectedAdmin?._id) > 0}
                  >
                    <Box
                      component={"img"}
                      src={EmailFilledIcon}
                      sx={{ width: "20px", marginRight: "5px" }}
                    />
                    Send Invitation{" "}
                    {getCooldownForAdmin(selectedAdmin?._id) > 0 &&
                      `(${getCooldownForAdmin(selectedAdmin?._id)}s)`}
                  </Button>
                )}
            </div>
            <Button
              variant="text"
              sx={{
                color: "#3D5347",
                textDecoration: "underline",
                textTransform: "none",
                fontSize: "13.5px",
                width: "150px",
                fontWeight: "600",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => {
                setShowLogs(true);
              }}
            >
              Check Logs
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ExternalAdminHandler2;

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import customScrollarStyles from "../../Shared/CustomScrollBar.module.css";
import {
  useAdminLedgers,
  useGetAdminById,
} from "../../../graphql/Admins/query";
import { AdminStatus, getAdminStatus } from "../helpers";

import toast from "react-hot-toast";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import ExternalAdminHandler1 from "./ExternalAdminHandler1";
import ExternalAdminHandler2 from "./ExternalAdminHandler2";
// import InternalAdminForm from "../InternalAdmins/InternalAdminForm";
// import { UpdatingAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminById";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { UPDATE_USER_STATUS } from "../../../graphql/Admins/mutation";
import { FetchMasjidById } from "../../../Redux/Actions/MasjidActions/FetchMasjidById";
import { GET_MASJID_BY_ID_FOR_MASJID_DETAILS } from "../../../graphql/Masjids/query";

const ValueFieldStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#1B8368",
  fontWeight: "bold",
};
const getValueFieldStyles = (value: any) => {
  if (value) {
    return ValueFieldStyles;
  } else {
    return { ...ValueFieldStyles, color: "#FF4949" };
  }
};
const ExternalAdminStatushandler = ({
  cooldowns,
  setCooldowns,
  selectedAdmin,
  setSelectedAdmin,
  open,
  setOpen,
  onClose,
  isLoading,
  refetch,
}: {
  cooldowns: { [key: string]: number };
  setCooldowns: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
  selectedAdmin: any;
  setSelectedAdmin: (admin: any) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  isLoading: boolean;
  refetch: () => void;
}) => {
  const [showLogs, setShowLogs] = useState(false);
  const [showReasonConfirmation, setShowReasonConfirmation] = useState(false);
  const [skipfetchLogs, setSkipfetchLogs] = useState(true);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);
  const [skipfetchAdmin, setSkipfetchAdmin] = useState(true);
  const [newStatus, setNewStatus] = useState("");
  const [showFreezeWarning, setShowFreezeWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const {
    loading: loadingLedgers,
    error: ledgerError,
    data: logs,
    refetch: refetchLogs,
  } = useAdminLedgers(
    selectedAdmin?._id ?? "",
    !selectedAdmin?._id || !showLogs
  );
  const [updateUserStatus, { data, loading, error }] =
    useMutation(UPDATE_USER_STATUS);

  const {
    loading: loadingAdmin,
    error: errorAdmin,
    data: dataAdmin,
    refetch: refetchAdmin,
  } = useGetAdminById(
    selectedAdmin?._id ?? "",
    !selectedAdmin?._id || skipfetchAdmin
  );

  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    if (dataAdmin && !loadingAdmin) {
      fetchAndMergeMasjidDetails(dataAdmin).then((updatedAdmin) => {
        setSelectedAdmin(updatedAdmin);
        setSkipfetchAdmin(true);
      });
    } else if (!dataAdmin && !skipfetchAdmin && !loadingAdmin) {
      setSkipfetchAdmin(true);
    } else if (errorAdmin && !loadingAdmin) {
      toast.error("Failed to Fetch Admin Details!");
      setSelectedAdmin(null);
      setSkipfetchAdmin(true);
    }
  }, [dataAdmin, loadingAdmin, errorAdmin]);
  const fetchAndMergeMasjidDetails = async (adminData: any) => {
    if (!adminData?.masjids?.length) return adminData; // No masjid IDs, return as is

    const masjidId = adminData.masjids[0]; // Consider only the first masjid ID
    try {
      const data = await dispatch(
        FetchMasjidById(masjidId, GET_MASJID_BY_ID_FOR_MASJID_DETAILS)
      );
      console.log(data);
      return { ...adminData, masjidDetails: [data] }; // Ensure it's an array
    } catch (error) {
      console.error("Error fetching masjid details:", error);
      return adminData; // Return original admin data even if masjid fetch fails
    }
  };

  const adminStatus = getAdminStatus(
    selectedAdmin?.isFreezed,
    selectedAdmin?.isVerified
  );

  const handleClose = () => {
    if (showReasonConfirmation) {
      setShowReasonConfirmation(false);
      setReason("");
      setReasonError(false);
    } else if (showLogs) {
      setShowLogs(false);
    } else {
      setOpen(false);
    }
  };
  const handleAction = (NewStatus: string) => {
    setNewStatus(NewStatus);
    setShowReasonConfirmation(true);
  };
  const handleCloseButton = () => {
    if (showReasonConfirmation) {
      setShowReasonConfirmation(false);
      setReason("");
      setReasonError(false);
    } else if (showLogs) {
      setShowLogs(false);
    } else {
      setOpen(false);
      onClose();
    }
  };
  const handleUpdateAdminStatus = async (status: string) => {
    console.log(status);
    if (!selectedAdmin?._id) {
      toast.error("Admin not found");
      return;
    }

    const toastId = toast.loading("Updating Status...");
    const freeze = status === "rejected";

    try {
      const response = await updateUserStatus({
        variables: {
          input: {
            userId: selectedAdmin._id,
            description: reason,
            status: status,
          },
          freeze: freeze,
        },
      });
      toast.dismiss(toastId);
      toast.success("Successfully Updated Admin Status");
      setReason("");
      setShowReasonConfirmation(false);
      setSkipfetchAdmin(false);
      refetch();
      console.log("Mutation response:", response.data);
    } catch (error) {
      toast.dismiss(toastId);
      setReason("");

      // Default fallback message
      let errorMessage = "Error Updating Admin Status";

      // If you are using Apollo, you can check for graphQLErrors:
      if (error instanceof ApolloError) {
        // If there's at least one GraphQL error, use its message
        if (error.graphQLErrors?.[0]?.message) {
          errorMessage = error.graphQLErrors[0].message;
        } else {
          // Or fall back to the Apollo error's main message
          errorMessage = error.message;
        }
      }
      // If it's a regular JS Error (not necessarily an ApolloError)
      else if (error instanceof Error) {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
      console.error("Error updating user status:", error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "22px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            borderBottom: "1px solid #ccc",
            color: "#3D5347",
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          {showLogs
            ? "Logs"
            : showReasonConfirmation
            ? "Reason"
            : "Admin Details"}
          <IconButton
            aria-label="close"
            onClick={handleCloseButton}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className={customScrollarStyles["custom-scrollbar"]}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent:
              isLoading || (showLogs && logs?.length <= 0)
                ? "center"
                : "flex-start",
            overflowX: "hidden",
            minHeight: showLogs && logs?.length > 0 ? "auto" : "400px",
            height: "100%",
          }}
        >
          {selectedAdmin?.isAddedFromAdmin ? (
            <ExternalAdminHandler2
              setCooldowns={setCooldowns}
              cooldowns={cooldowns}
              isLoading={isLoading || loadingLedgers || loadingAdmin}
              showReasonConfirmation={showReasonConfirmation}
              setShowReasonConfirmation={setShowReasonConfirmation}
              handleUpdateAdminStatus={handleUpdateAdminStatus}
              adminStatus={adminStatus}
              logs={logs}
              reason={reason}
              setReason={setReason}
              reasonError={reasonError}
              setReasonError={setReasonError}
              selectedAdmin={selectedAdmin}
              showLogs={showLogs}
              setShowLogs={setShowLogs}
              refetch={refetch}
              setOpen={setOpen}
              setSkipfetchAdmin={setSkipfetchAdmin}
            ></ExternalAdminHandler2>
          ) : (
            <>
              <ExternalAdminHandler1
                isLoading={isLoading || loadingLedgers || loadingAdmin}
                showReasonConfirmation={showReasonConfirmation}
                handleUpdateAdminStatus={handleUpdateAdminStatus}
                adminStatus={adminStatus}
                logs={logs}
                reason={reason}
                setReason={setReason}
                reasonError={reasonError}
                setReasonError={setReasonError}
                selectedAdmin={selectedAdmin}
                showLogs={showLogs}
                setShowLogs={setShowLogs}
                handleAction={handleAction}
                newStatus={newStatus}
                refetch={refetch}
                setOpen={setOpen}
                setSkipfetchAdmin={setSkipfetchAdmin}
              ></ExternalAdminHandler1>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExternalAdminStatushandler;

import { refreshToken } from "../../APICalls";

let isRefreshing = false;
let refreshPromise: Promise<void> | null = null;

export async function handleRefreshToken() {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshPromise = refreshToken()
      .then(() => {
        isRefreshing = false;
      })
      .catch(err => {
        isRefreshing = false;
        throw err;
      });
  }

  return refreshPromise;
}

import { MasjidStatus, MasjidStatusDisplay } from "../Enums/enums";

export const getFormattedMasjidStatus = (masjidStatus: string) => {
  if (masjidStatus === MasjidStatus.VERIFIED || masjidStatus === null) {
    return MasjidStatusDisplay.VERIFIED;
  } else if (masjidStatus === MasjidStatus.REJECTED) {
    return MasjidStatusDisplay.REJECTED;
  } else if (masjidStatus === MasjidStatus.UNVERIFIED) {
    return MasjidStatusDisplay.UNVERIFIED;
  } else if (masjidStatus === "frozen") {
    return MasjidStatusDisplay.REJECTED;
  } else {
    return "";
  }
};

export const getActualMasjidStatus = (masjidStatus: string) => {
  if (masjidStatus === MasjidStatusDisplay.VERIFIED) {
    return MasjidStatus.VERIFIED;
  } else if (masjidStatus === MasjidStatusDisplay.REJECTED) {
    return MasjidStatus.REJECTED;
  } else if (masjidStatus === MasjidStatusDisplay.UNVERIFIED) {
    return MasjidStatus.UNVERIFIED;
  } else {
    return "";
  }
};

export const getAction = (status: string) => {
  switch (status) {
    case MasjidStatus.VERIFIED:
      return "Verifying";
    case MasjidStatus.REJECTED:
      return "Rejecting";
    case MasjidStatus.UNVERIFIED:
      return "Unverifying";
    default:
      return "";
  }
};

export const getStatus = (isFreeze: boolean, isVerified: boolean) => {
  if (isFreeze) {
    return "rejected";
  }

  if (isVerified) {
    return "approved";
  }

  return "pending";
};
export const getValidUrl = (url: string) => {
  if (!url) return "";
  // Check if URL already starts with http or https
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }
  // Otherwise, add https://
  return `https://${url}`;
};

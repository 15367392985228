import React from "react";
import { announceInputType } from "../AnnouncementFormComponent/AnnouncementFormComponent";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import moment from "moment";

type PreviewProps = {
  announcementData: announceInputType;
  onCancel: () => void;
  onSubmit: () => void;
};

const AnnouncementPreviewComponent: React.FC<PreviewProps> = ({
  announcementData,
  onCancel,
  onSubmit,
}) => {
  return (
    <Dialog
      className="announcement-preview-modal"
      open={true}
      onClose={onCancel}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        Announcement Preview
      </DialogTitle>
      <DialogContent style={{ borderRadius: "30px", textAlign: "center" }}>
        <DialogContentText>
          <Typography
            variant="h6"
            style={{ marginBottom: "15px", fontWeight: "bold" }}
          >
            Title: {announcementData.title}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            Body: {announcementData.desc}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "15px" }}>
            Sticky: {announcementData?.isSticky ? "Yes" : "No"}
          </Typography>
          {announcementData?.expiresAt && (
            <Typography variant="body1" style={{ marginBottom: "15px" }}>
              Expires At:{" "}
              {moment(announcementData.expiresAt).format(
                "DD MMM, YYYY hh:mm a"
              )}
            </Typography>
          )}
          {!announcementData?._id && (
            <>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", borderBottom: "2px solid gray" }}
              >
                Selected Masjids:
              </Typography>
              <List style={{ textAlign: "center" }}>
                {announcementData?.selectedMasjids?.length > 0
                  ? announcementData?.selectedMasjids?.map((masjid, index) => (
                      <ListItem key={index}>
                        <ListItemText>
                          {index + 1}. {masjid.masjidName}
                        </ListItemText>
                      </ListItem>
                    ))
                  : "All Masjids"}
              </List>
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={onSubmit} color="primary" variant="contained">
          Submit
        </Button>
        <Button onClick={onCancel} color="secondary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnnouncementPreviewComponent;

import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { CellDataType, Masjid } from "../../../../Types";
import { Typography } from "@mui/material";
import moment from "moment";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import { getFormattedMasjidStatus, getStatus } from "./Helper";
const today = moment();

export const getMasjidColumns = (
  handleActionClick: (action: string, details: Masjid) => void
): any[] => [
  {
    field: "masjidName",
    headerName: "Masjid Name",
    autoComplete: "off",
    width: 250,
    renderCell: (params: CellDataType) => {
      return (
        <div className="cellWithImg">
          <LazyLoadImage
            effect="blur"
            className="cellImg"
            src={params.row.masjidProfilePhoto}
            alt="avatar"
          />
          {getStatus(params.row?.isFreezed, params.row?.isVerified) !==
          "rejected" ? (
            <Link
              to={"/masjid/" + params.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {params.row.masjidName}
            </Link>
          ) : (
            <Typography
              style={{
                color: "grey",
                fontSize: "0.8rem",
                cursor: "not-allowed",
              }}
            >
              {params.row.masjidName}
            </Typography>
          )}
        </div>
      );
    },
  },
  {
    field: "contact",
    headerName: "Contact",
    autoComplete: "off",
    sortable: false,
    width: 140,
  },
  {
    field: "Days Before",
    headerName: "Days since last update",
    autoComplete: "off",
    width: 100,
    hide: true,
    renderCell: (params: CellDataType) => {
      return (
        <div>
          {moment(today.format()).diff(moment(params.row.updatedAt), "days")}
        </div>
      );
    },
    valueGetter: (params: CellDataType) =>
      moment(today.format()).diff(moment(params.row.updatedAt), "days"),
  },
  {
    field: "email",
    headerName: "email",
    width: 200,
    editable: "never",
    filterable: true,
    hide: true,
    renderCell: (params: CellDataType) => {
      for (const Link of params.row.externalLinks) {
        if (Link.name !== "Email") continue;
        else if (Link.name === "Email") {
          return <div>{Link.url}</div>;
        }
      }
    },
  },
  {
    field: "updatedBy",
    headerName: "Last Updated By",
    width: 140,
    // editable: "never",
    sortable: false,
    renderCell: (params: CellDataType) => {
      return <div>{params.row?.updatedBy?.name}</div>;
    },
    valueGetter: (params: CellDataType) => params.row.updatedBy?.name,
  },
  {
    field: "address",
    headerName: "Address",
    width: 300,
    autoComplete: "off",
    sortable: false,
  },
  {
    field: "assignedUser[0].role",
    headerName: "Role",
    sortable: false,
    width: 110,
    valueGetter: (params: CellDataType) =>
      params.row.assignedUser?.length > 0
        ? roleRenamer(params.row.assignedUser[0].role)
        : "",
    renderCell: (params: CellDataType) => {
      let color;
      if (params.row.assignedUser?.length > 0) {
        if (
          params.row.assignedUser[0].role === "superadmin" ||
          params.row.assignedUser[0].role === "admin"
        ) {
          color = "blue"; // Set the color for superadmin/admin
        } else if (
          params.row.assignedUser[0].role === "subadmin" ||
          params.row.assignedUser[0].role === "musaliadmin"
        ) {
          color = "green"; // Set the color for subadmin/musalliadmin
        } else {
          color = "black"; // Default color if the role is not recognized
        }
        const role = params.row.assignedUser[0].role;
        const displayRole = roleRenamer(role);
        return (
          <Typography style={{ color }} variant="body2">
            {displayRole}
          </Typography>
        );
      } else {
        return "";
      }
      // Check the role and set the color accordingly
    },
  },
  // {
  //   field: "Responsibility of",
  //   headerName: "Responsibility of",
  //   width: 150,
  //   valueGetter: (params: CellDataType) => params.row?.responsibilityOf,
  // },
  {
    field: "isVerified",
    headerName: "Masjid Status",
    width: 110,
    valueGetter: (params: CellDataType) => {
      const value = getFormattedMasjidStatus(
        getStatus(params.row?.isFreezed, params.row?.isVerified)
      );
      return value;
    },
    renderCell: (params: CellDataType) => {
      let color;
      const value = getStatus(params.row?.isFreezed, params.row?.isVerified);
      // Check the role and set the color accordingly
      if (value === "approved" || value === null) {
        color = "#1B8368";
      } else if (value === "pending") {
        color = "#EDA920";
      } else {
        color = "#FF4949";
      }
      return (
        <Typography
          style={{ color, cursor: "pointer", textTransform: "capitalize" }}
          variant="body2"
          onClick={() => {
            handleActionClick(value, params.row);
          }}
        >
          {getFormattedMasjidStatus(
            getStatus(params.row?.isFreezed, params.row?.isVerified)
          )}
        </Typography>
      );
    },
  },
  {
    field: "assignedUser[0].name",
    headerName: "Admin Name",
    width: 150,
    sortable: false,
    valueGetter: (params: CellDataType) =>
      params.row.assignedUser?.length > 0
        ? params.row.assignedUser[0].name
        : "",
  },
  {
    field: "updatedAt",
    headerName: "Last Updated On",
    width: 150,
    valueGetter: (params: CellDataType) =>
      moment.utc(params.row.updatedAt).local().format("DD-MMM-YYYY HH:mm:ss"),
    // moment().format("MM/DD/YYYY hh:mm a"),
  },
  {
    field: "followers",
    headerName: "Followers",
    width: 150,
    valueGetter: (params: CellDataType) => params.row.followers ?? 0,
    // moment().format("MM/DD/YYYY hh:mm a"),
  },
  {
    field: "subscribers",
    headerName: "Musali",
    width: 150,
    valueGetter: (params: CellDataType) => params.row.subscribers ?? 0,
    // valueGetter: (params: CellDataType) =>
    //   params.row.updatedAt,
    // moment().format("MM/DD/YYYY hh:mm a"),
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 150,
    // flex: 1,
    // hideable: true,
    hide: true,
    valueGetter: (params: any) => {
      const value = params.row.createdAt;
      return moment.utc(value).local().format("DD-MMM-YYYY HH:mm:ss");
    },
  },
];

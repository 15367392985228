import React from "react";
import { Modal, Paper, Typography, Button } from "@material-ui/core";

type ConfirmationProps = {
  open: boolean;

  onYes: () => void;
  onNo: () => void;
  message?: string;
};

const ConfirmationModal: React.FC<ConfirmationProps> = ({
  open,
  onYes,
  onNo,
  message,
}) => {
  const handleOnYes = () => {
    onYes();
  };
  return (
    <>
      <Modal open={open} onClose={onNo}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Paper
            style={{ padding: "20px", maxWidth: "400px", textAlign: "center" }}
          >
            <Typography variant="h5" gutterBottom>
              Confirmation
            </Typography>
            <Typography variant="body1" paragraph>
              {message ??
                "Are you sure you want to proceed with the announcement?"}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button variant="contained" color="primary" onClick={handleOnYes}>
                Yes
              </Button>
              <Button variant="contained" color="secondary" onClick={onNo}>
                No
              </Button>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationModal;

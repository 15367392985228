import React, {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  useMemo,
  useCallback,
} from "react";

// import TopBar from "../../FeedComponents/TopBar/TopBar";
// import AllAdminsTable from "../../PageComponents/AllAdminsTable/AllAdminsTable";
// import { useSelector } from "react-redux";
import "./AnnouncementFormComponent.css";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import { CircularProgress } from "@material-ui/core";
import { AnnouncementNotification } from "../../../Redux/Actions/NotificationActions/AnnouncementNotificationAction";
import { getMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjids";
// import { getAdminMasjids } from "../../../Redux/Actions/MasjidActions/FetchingMasjidByAdmin.js";
import { getStaleMasjids } from "../../../Redux/Actions/MasjidActions/FetchingStaleMasjids";
import { handleSnackbar } from "../../../helpers/SnackbarHelper/SnackbarHelper";
import { useAppSelector, useAppThunkDispatch } from "../../../Redux/hooks";
import { AdminInterFace } from "../../../Components/FeedComponents/Feed/Feed";
import { AnnouncementType, Masjid } from "../../../../Types";
import { ResponseType } from "../../../../Types";
import { toast } from "react-hot-toast";
import MasjidsAutocomplete from "../../../Pages/Shared/MasjidsAutocomplete/MasjidsAutocomplete";
import { Backdrop, InputAdornment, Modal, Typography } from "@mui/material";
import AnnouncementPreviewComponent from "../AnnouncementPreviewComponent/AnnouncementPreviewComponent";
import CustomAlert from "../../../Pages/Shared/CustomAlert/CustomAlert";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import OTPDialog from "../../../Pages/Shared/OTPDialog/OTPDialog";
import { adminFromLocalStg } from "../../../helpers/AdminFromLocalStorage/AdminFromLocalStorage";
import { AnnouncementOTPVerifierAction } from "../../../Redux/Actions/NotificationActions/AnnouncementOTPVerifierAction";
import {
  CarouselImageUploader,
  CustomCalender,
} from "@msa-software-llc/connectmazjid-vanilaportal";
import { uploadImage } from "../../../helpers/ImageUploader/imageUploader";
import MultiMasjidSelectDropdown from "../../Shared/MulitMasjidSelectDropdown/MultiMasjidSelectDropdown";
import CustomRadio from "../../Shared/CustomRadio/customRadio";
import moment from "moment";
import calendar from "../../../Photos/Newuiphotos/Icons/calender.svg";
import { MdCancel } from "react-icons/md";
import { AnnouncementNotificationUpdate } from "../../../Redux/Actions/NotificationActions/AnnouncementNotificationUpdateAction";
export type announceInputType = {
  _id?: string;
  title: string;
  desc: string;
  selectedMasjids: Masjid[];
  image: File | null | string;
  isSticky: boolean;
  expiresAt: string;
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

type PropsType = {
  setViewingAnnouncement: (value: boolean) => void;
  existingAnnouncement?: AnnouncementType | null;
  refetchAnnoucements?: () => void;
};

const defaultAnnouncementInfo = {
  title: "",
  desc: "",
  selectedMasjids: [],
  image: null,
  isSticky: false,
  expiresAt: "",
};
const AnnouncementFormComponent = ({
  setViewingAnnouncement,
  existingAnnouncement,
  refetchAnnoucements,
}: PropsType) => {
  const [openOTPModal, setOpenOTPModal] = useState<boolean>(false);
  const [admin, setAdmin] = useState<AdminInterFace>(adminFromLocalStg());
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(""); // Assuming you have state for email
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const [isLoadingStyles, setIsLoadingStyles] = useState(true);
  useEffect(() => {
    const styleElement = document.createElement("style");

    const loadDynamicStyles = async () => {
      const { default: vanillaPortalStyle } = await import(
        "@msa-software-llc/connectmazjid-vanilaportal/dist/style.css"
      );
      // Append the stylesheet to the document head
      styleElement.textContent = vanillaPortalStyle;
      document.head.appendChild(styleElement);
      setIsLoadingStyles(false);
    };

    loadDynamicStyles();

    return () => {
      const indexOfMyCss = Array.from(document.head.children).indexOf(
        styleElement
      );
    };
  }, []);
  const handleOtpApiRequest = (otp: string) => {
    setIsVerifying(true);

    let formData = {
      email: admin?.email ?? "",
      otp: String(otp),
    };

    const res = dispatch(AnnouncementOTPVerifierAction(formData));
    res.then((result: any) => {
      console.log(result);
      if (result.success) {
        toast.success(result.message);
        clearForm();
      } else {
        console.log("error occured");
        toast.error(`Failed To Verify OTP.`);
        clearForm();
      }
      setIsVerifying(false);
      setOpenOTPModal(false);
    });
  };

  const [isTriggering, setisTriggering] = useState(false);
  const [SelectedAll, setSelectedAll] = useState(false);

  const [showPreview, setShowPreview] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [announcementData, setAnnouncementData] = useState<announceInputType>(
    defaultAnnouncementInfo
  );
  const dispatch = useAppThunkDispatch();
  const staleMasjids = useAppSelector((state) => state.staleMasjids);
  const masjids = useAppSelector((state) => state.masjids);

  const clearForm = () => {
    setAnnouncementData(defaultAnnouncementInfo);
    setSelectedAll(false);
    
  };
  // const handleChange = (masjid: Masjid[] | null) => {
  //   const existingMasjids = announcementData.selectedMasjids;
  //   const newMasjids =
  //     masjid?.filter(
  //       (newMasjid) =>
  //         !existingMasjids.some(
  //           (existingMasjid) => existingMasjid._id === newMasjid._id
  //         )
  //     ) ?? [];

  //   setAnnouncementData({
  //     ...announcementData,
  //     selectedMasjids: [...existingMasjids, ...newMasjids],
  //   });
  // };
  const handleChange = (masjid: Masjid[] | null) => {
    setAnnouncementData({
      ...announcementData,
      selectedMasjids: masjid ?? [],
    });
  };
  const handleMasjidToggle = () => {
    if (SelectedAll) {
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
      setAnnouncementData((prevState) => ({
        ...prevState,
        selectedMasjids: [],
      }));
    }
  };

  const handleAnnoucementNetworkCall = async () => {
    // if (SelectedAll) {
    //   setShowConfirmation(false);
    //   setOpenOTPModal(true);
    //   return;
    // }
    const Ids =
      existingAnnouncement?.masjids && existingAnnouncement?.masjids?.length > 0
        ? existingAnnouncement?.masjids?.map((item) => item._id)
        : SelectedAll
        ? []
        : announcementData.selectedMasjids.map((item) => item._id);
    const uploadIfNeeded = async () => {
      if (announcementData?.image && announcementData.image instanceof File) {
        toast.loading("Uploading image...");
        try {
          const uploadedUrl = await uploadImage(announcementData.image);
          // toast.dismiss();
          // toast.success("Image uploaded successfully");
          return uploadedUrl;
        } catch (error) {
          toast.dismiss();
          toast.error("Image upload failed");
          throw error; // Prevent further execution
        }
      } else if (announcementData?.image) {
        return announcementData.image;
      }
      return ""; // Return existing image URL if no upload needed
    };

    // Upload the image if needed
    const imageUrl = await uploadIfNeeded();

    toast.dismiss();
    let uploadData = {
      masjidIds: Ids,
      title: announcementData.title,
      body: announcementData.desc,
      // isSticky: announcementData.isSticky,
      isSticky: false,
      image: imageUrl,
      priorityType: "high",
      // expiresAt: announcementData.expiresAt,
      expiresAt: "",
    };

    // Show toast loader while API call is in progress
    const loadingToastId = toast.loading(
      existingAnnouncement?._id
        ? "Updating Announcement..."
        : "Sending notification..."
    );
    const data = existingAnnouncement?._id
      ? dispatch(
          AnnouncementNotificationUpdate(existingAnnouncement._id, uploadData)
        )
      : dispatch(AnnouncementNotification(uploadData));
    data.then(function (result: ResponseType) {
      if (result.success) {
        // Close loading toast
        toast.dismiss(loadingToastId);
        if (!existingAnnouncement) {
          setShowConfirmation(false);
          setOpenOTPModal(true);
        } else {
          setViewingAnnouncement(true);
          refetchAnnoucements && refetchAnnoucements();
        }
      } else {
        // Close loading toast
        toast.dismiss(loadingToastId);
        setisTriggering(false);
        handleSnackbar(
          true,
          "warning",
          "Failed to Send the Notification to the Application",
          dispatch
        );
        clearForm();
      }
      setShowConfirmation(false);
    });
  };

  const handleTriggerNotification = () => {
    setisTriggering(true);
    if (!SelectedAll) {
      if (
        announcementData.title &&
        announcementData.desc &&
        (existingAnnouncement || announcementData.selectedMasjids.length > 0)
      ) {
        // handleAnnoucementNetworkCall();
        setShowPreview(true);
      } else {
        setisTriggering(false);
        handleSnackbar(
          true,
          "warning",
          "Please Enter all Details Properly",
          dispatch
        );
      }
    } else if (SelectedAll) {
      if (announcementData.title && announcementData.desc && SelectedAll) {
        // handleAnnoucementNetworkCall();
        setShowPreview(true);
      } else {
        setisTriggering(false);
        handleSnackbar(
          true,
          "warning",
          "Please Enter all Details Properly",
          dispatch
        );
      }
    } else {
      setisTriggering(false);

      handleSnackbar(
        true,
        "warning",
        "Couldnt Send the Notification : Something Went Wrong ",
        dispatch
      );
    }
  };

  const handleClick = () => {
    setViewingAnnouncement(true);
  };

  const handleCancelOrSubmit = (isSubmit = false) => {
    setShowPreview(false);
    setisTriggering(false);
    if (isSubmit) setShowConfirmation(true);
  };
  const handleImageUpload = (e: any) => {
    const newImages = [];
    newImages.push(e.target.files[0]);
    setAnnouncementData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };
  const handleDeleteImage = () => {
    setAnnouncementData((prevState) => ({
      ...prevState,
      image: null,
    }));
  };
  const handleDateSelect = useCallback((date: Date) => {
    // Use moment to parse the date in the specific timezone
    const dateInTz = moment(date);

    // Convert it to UTC before storing in the database
    const formattedDate = dateInTz.utc().format();

    setAnnouncementData((prev) => ({
      ...prev,
      expiresAt: formattedDate,
    }));

    setIsCalendarOpen(false);

    // Clear validation error for expiresAt if it exists
    // if (validationErrors.expiresAt) {
    //   setValidationErrors((prev) => ({
    //     ...prev,
    //     expiresAt: false,
    //   }));
    // }
  }, []);

  useEffect(() => {
    setAnnouncementData({
      _id: existingAnnouncement?._id ?? "",
      title: existingAnnouncement?.title ?? "",
      desc: existingAnnouncement?.body ?? "",
      selectedMasjids: [],
      image: existingAnnouncement?.image ?? "",
      isSticky: existingAnnouncement?.isSticky ?? false,
      expiresAt: existingAnnouncement?.expiresAt ?? "",
    });
  }, [existingAnnouncement]);
  const handleOpenCalendar = useCallback(() => {
    setIsCalendarOpen(true);
  }, []);
  const getExpiresAtDate = useCallback(() => {
    if (!announcementData.expiresAt) return undefined;
    return moment(announcementData.expiresAt).toDate();
  }, [announcementData.expiresAt]);
  const uploaderImages = useMemo(() => {
    if (!announcementData?.image) return [];
    if (announcementData.image instanceof File) {
      return [
        {
          url: URL.createObjectURL(announcementData?.image),
          alt: "Image 0",
          createdByObjectURL: true, // Mark this URL as created by URL.createObjectURL
        },
      ];
    } else {
      return [
        {
          url: announcementData?.image,
          alt: "Image 0",
          createdByObjectURL: false, // Mark this URL as created by URL.createObjectURL
        },
      ];
    }
  }, [announcementData?.image]);
  return (
    <>
      <div className="AnnouncementContainer">
        <div className="AnnouncementContainerBody">
          <Box sx={{ position: "relative" }}>
            <Typography
              variant="h6"
              style={{
                textAlign: "center",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              {existingAnnouncement
                ? "Update Announcement"
                : "Make Announcement"}
            </Typography>
            {existingAnnouncement && (
              <MdCancel
                size={25}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              />
            )}
          </Box>
          {isLoadingStyles ? (
            <CircularProgress />
          ) : (
            <CarouselImageUploader
              images={uploaderImages}
              onUpload={handleImageUpload}
              onDelete={handleDeleteImage}
              isCarousel={false}
            ></CarouselImageUploader>
          )}

          <TextField
            value={announcementData.title}
            autoFocus
            margin="dense"
            id="name"
            autoComplete="Off"
            error={isTriggering && !announcementData.title}
            helperText={
              isTriggering && !announcementData.title ? "Manditory field" : " "
            }
            label="Announcement Title"
            onChange={(e) => {
              setAnnouncementData({
                ...announcementData,
                title: e.target.value,
              });
            }}
            sx={{ marginBottom: 3 }}
            type="text"
            fullWidth
            variant="outlined"
          />
          <TextField
            value={announcementData.desc}
            id="outlined-required"
            error={isTriggering && !announcementData.desc}
            helperText={
              isTriggering && !announcementData.desc ? "Manditory field" : " "
            }
            variant="outlined"
            multiline
            rows={5}
            autoComplete="Off"
            onChange={(e) => {
              setAnnouncementData({
                ...announcementData,
                desc: e.target.value,
              });
            }}
            label="Announcement Body"
            fullWidth
            type="email"
            sx={{ marginBottom: 3 }}
          />
          {/* <CustomRadio
            options={[
              { value: "showNow", label: "Show Now" },
              { value: "sticky", label: "Sticky" },
            ]}
            value={announcementData.isSticky ? "sticky" : "showNow"}
            onChange={(value) => {
              setAnnouncementData((prev) => ({
                ...prev,
                isSticky: value === "sticky",
                expiresAt:
                  value === "showNow"
                    ? moment().endOf("day").toISOString()
                    : announcementData.expiresAt,
              }));
            }}
          /> */}
          {announcementData.isSticky && (
            <Box sx={{ mb: "1.5rem", mt: "1.5rem" }}>
              <TextField
                fullWidth
                label="Expire On"
                name="expiresAt"
                // value={tZone && formatDate(formData.expiresAt, tZone)}
                value={
                  announcementData?.expiresAt
                    ? moment(announcementData.expiresAt).format("DD MMM YYYY")
                    : ""
                }
                onClick={handleOpenCalendar}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <img
                        src={calendar}
                        alt="calendar"
                        onClick={handleOpenCalendar}
                        style={{
                          cursor: "pointer",
                          color: "#545454",
                          marginRight: "10px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                // error={validationErrors.expiresAt}
                // helperText={
                //   validationErrors.expiresAt
                //     ? "Expiration date is required for sticky announcements"
                //     : ""
                // }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "2em",
                    fontSize: {
                      xs: "0.9rem",
                      sm: "1rem",
                    },
                    cursor: "pointer",
                    "& fieldset": {
                      borderColor: "#545454",
                    },
                    "&:hover fieldset": {
                      borderColor: "#545454",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#545454",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#545454",
                    fontSize: {
                      xs: "0.9rem",
                      sm: "1rem",
                    },
                  },
                }}
              />
            </Box>
          )}
          {!existingAnnouncement && (
            <>
              <span className="itemKey">
                Choose All Masjids
                <Switch
                  checked={SelectedAll}
                  onChange={handleMasjidToggle}
                  name="loading"
                  color="primary"
                />
              </span>
              {!SelectedAll && (
                <MultiMasjidSelectDropdown
                  selectedMasjids={announcementData?.selectedMasjids ?? []}
                  handleChange={handleChange}
                  isMultiple={true}
                />
              )}
              {!announcementData.selectedMasjids.length && !SelectedAll ? (
                <span style={{ color: "red" }}>
                  Choose Masjids Please Before triggering
                </span>
              ) : (
                <> </>
              )}
            </>
          )}

          <button className="ButtonToggle" onClick={handleTriggerNotification}>
            <span className="text">
              {isTriggering ? (
                <CircularProgress size="20px" />
              ) : existingAnnouncement ? (
                "Update Announcement"
              ) : (
                "Triggering Announcement"
              )}
            </span>
          </button>
          {!existingAnnouncement && (
            <div className="AnnouncementTtitleContainerForm">
              <button className="ButtonToggleViewAll" onClick={handleClick}>
                View All Announcements
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal open={showPreview} onClose={() => setShowPreview(false)}>
        <AnnouncementPreviewComponent
          announcementData={announcementData}
          onCancel={() => handleCancelOrSubmit(false)}
          onSubmit={() => handleCancelOrSubmit(true)}
        />
      </Modal>
      <ConfirmationModal
        open={showConfirmation}
        onYes={() => handleAnnoucementNetworkCall()}
        onNo={() => setShowConfirmation(false)}
      />

      <OTPDialog
        open={openOTPModal}
        email={admin.email}
        setOpen={setOpenOTPModal}
        handleOtpApiRequest={handleOtpApiRequest}
        isVerifying={isVerifying}
      />
      {isCalendarOpen && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isCalendarOpen}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setIsCalendarOpen(false);
            }
          }}
        >
          <Box
            sx={{
              p: 2,
              borderRadius: "1rem",
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <CustomCalender
              onDateSelect={handleDateSelect}
              minDate={new Date(moment().format("YYYY-MM-DD HH:mm:ss"))}
              value={getExpiresAtDate()}
              setValue={(date: Date) => handleDateSelect(date)}
              tileDisabled={() => false}
            />
          </Box>
        </Backdrop>
      )}
    </>
  );
};

export default AnnouncementFormComponent;

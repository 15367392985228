// src/apolloClient.ts
import { ApolloClient, InMemoryCache, Observable, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { adminFromLocalStg } from "./helpers/AdminFromLocalStorage/AdminFromLocalStorage";
import { refreshToken } from "./APICalls";
import { onError } from "@apollo/client/link/error";
import { AuthTokens } from "../Types";
import { handleRefreshToken } from "./helpers/RefreshTokenHelper/tokenHelper";


const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${
            locations || ""
          }, Path: ${path}`
        )
      );
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);

      // Check if the error is 401 Unauthorized
      if ("statusCode" in networkError && networkError.statusCode === 401) {
        console.log("401 Unauthorized detected");
        

        return new Observable((observer) => {
          handleRefreshToken()
          ?.then(() => {
              const authTokensString = localStorage.getItem("authTokens");
              const token: AuthTokens | null = authTokensString
                ? JSON.parse(authTokensString)
                : null;

              if (token) {
                // Retry the failed operation with the new token
                const oldHeaders = operation.getContext().headers;
                const newHeaders = {
                  ...oldHeaders,
                  authorization: `Bearer ${token.accessToken}`,
                };
                operation.setContext({ headers: newHeaders });

                forward(operation).subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                });
              } else {
                throw new Error("No auth token available");
                // No token available, redirect to login or handle accordingly
                observer.error(new Error("No auth token available"));
              }
            })
            .catch((error) => {
              localStorage.clear();
              localStorage.removeItem("authTokens");
              localStorage.removeItem("admin");
              window.location.reload();
              console.error("Token refresh failed", error);
              // Optionally, redirect to login page
              observer.error(error);
            });
        });
      }
    }
  }
);

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL || "",
});

const authLink = setContext((_, { headers }) => {
  const authTokens = adminFromLocalStg("authTokens");
  const token = authTokens?.accessToken || "";
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;

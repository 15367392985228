import React, { useEffect } from "react";
import {
  List,
  Card,
  CardContent,
  Typography,
  Avatar,
  ListItemAvatar,
  Box,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Person } from "../CRM";
import { useQuery } from "@apollo/client";
import { GET_MEMBERS } from "../../../graphql/queries";
import "./MasjidList.css";

interface MasjidPersonsListProps {
  selectedPerson: Person;
  masjidId: string;
  reload: boolean;
  selectedPersonHandler: (target: any, masjid: Person) => void;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const MasjidPersonsList: React.FC<MasjidPersonsListProps> = ({
  selectedPerson,
  masjidId,
  selectedPersonHandler,
  reload,
  setReload,
}) => {
  const { loading, error, data, refetch } = useQuery(GET_MEMBERS, {
    variables: { masjidId: masjidId },
  });
  // const { loading, error, data, refetch } = useQuery(GET_MEMBERS, {
  //   variables: { masjidId },
  // });

  useEffect(() => {
    if (reload) {
      setReload(false);
      refetch();
    }
  }, [reload, refetch]);
  const masjidPersons: Person[] = data?.getMembers;
  return (
    <>
      {masjidPersons ? (
        <List
          style={{ height: "400px", overflowY: "auto", padding: "10px" }}
          className="custom-scrollbar"
        >
          {masjidPersons.map((masjidPerson, index) => (
            <Card
              key={index}
              onClick={(e) => selectedPersonHandler(e.target, masjidPerson)}
              style={{
                display: "flex",
                height: "80px",
                padding: "8px",
                marginBottom: "10px",
                cursor: "pointer",
                boxShadow:
                  "0px 4px 10px rgba(0, 0, 0, 0.3), 0px 4px 20px rgba(0, 0, 0, 0.2)",
                // justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "16px",
                position: "relative",
              }}
            >
              <ListItemAvatar>
                <Avatar>{masjidPerson.personName.charAt(0)}</Avatar>
              </ListItemAvatar>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0px",
                  overflow: "hidden",
                  alignSelf: "baseline",
                  // height: "80%"
                }}
              >
                <Typography variant="body1">
                  {masjidPerson?.personName}
                </Typography>
                <Box>
                  <Typography
                    color="textSecondary"
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {masjidPerson.email}
                  </Typography>
                  <Typography color="textSecondary">
                    {masjidPerson.phoneNumber}
                  </Typography>
                </Box>
              </CardContent>
              {/* <IconButton
                style={{ position: "absolute", top: "5px", right: "5px" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton> */}
            </Card>
          ))}
        </List>
      ) : null}
    </>
  );
};

export default MasjidPersonsList;

import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import ReasonConfirmation from "./ReasonConfirmation";
import { AdminStatus, getFormattedAdminStatus } from "../helpers";
import rejectedBtnCross from "../../../Photos/Newuiphotos/VerificationCenter/rejectedBtnCross.webp";
import approvedBtnTick from "../../../Photos/Newuiphotos/VerificationCenter/approvedBtnTick.webp";
import moment from "moment";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import StatusLogs from "../StatusLogs/StatusLogs";
import toast from "react-hot-toast";
import ChangeIcon from "../../../Photos/Newuiphotos/Admins/exchange.png";
import Dropdown from "../InternalAdmins/customDropdown/CustomDropdown";
import { Masjid } from "../../../../Types";
import { useAppThunkDispatch } from "../../../Redux/hooks";
import { DeleteMasjidInAdminById } from "../../../Redux/Actions/AdminActions/DeleteMasjidInAdminById";
import { UpdateMasjidInAdminById } from "../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { useSearchMasjids } from "../../../graphql/Masjids/query";
import { ApolloError, useMutation } from "@apollo/client";
import { USER_MASJID_ACTION } from "../../../graphql/Admins/mutation";
import ExternalAdminHandler2 from "./ExternalAdminHandler2";

// 1. Import the copy icon from Material-UI
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ValueFieldStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#1B8368",
  fontWeight: "bold",
};
const getValueFieldStyles = (value: any) => {
  if (value) {
    return ValueFieldStyles;
  } else {
    return { ...ValueFieldStyles, color: "#FF4949" };
  }
};

interface ExternalAdminHandler1Props {
  isLoading: boolean;
  showReasonConfirmation: boolean;
  handleUpdateAdminStatus: (status: string) => void;
  adminStatus: string;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  reasonError: boolean;
  setReasonError: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAdmin: any;
  showLogs: boolean;
  setShowLogs: React.Dispatch<React.SetStateAction<boolean>>;
  logs: any;
  handleAction: (status: string) => void;
  newStatus: string;
  refetch: () => void;
  setOpen: (open: boolean) => void;
  setSkipfetchAdmin: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExternalAdminHandler1 = ({
  isLoading,
  showReasonConfirmation,
  handleUpdateAdminStatus,
  adminStatus,
  reason,
  setReason,
  reasonError,
  setReasonError,
  selectedAdmin,
  showLogs,
  setShowLogs,
  logs,
  handleAction,
  newStatus,
  refetch,
  setOpen,
  setSkipfetchAdmin,
}: ExternalAdminHandler1Props) => {
  const dispatch = useAppThunkDispatch();

  const [isChangingMasjid, setIsChangingMasjid] = useState(false);
  const [selectedMasjid, setSelectedMasjid] = useState<Masjid | null>(null);
  const [isMasjidChanged, setIsMasjidChanged] = useState(false);

  const [masjidsList, setMasjidsList] = useState<Masjid[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    masjids,
    loading,
    error,
    refetch: masjidRefetch,
  } = useSearchMasjids(searchQuery);

  const [
    updateAdminMasjid,
    {
      data: updateAdminMasjidData,
      loading: updateAdminMasjidLoading,
      error: updateAdminMasjidError,
    },
  ] = useMutation(USER_MASJID_ACTION);

  useEffect(() => {
    if (!loading && !error) {
      if (masjids?.length > 0) {
        setMasjidsList(masjids);
      } else {
        setMasjidsList([]);
      }
    } else if (!loading && error) {
      setMasjidsList([]);
    }
  }, [masjids, loading, error]);

  useEffect(() => {
    setMasjidsList([]);
    if (searchQuery) {
      masjidRefetch();
    }
  }, [searchQuery, masjidRefetch]);

  const debouncedSearchMasjids = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    []
  );

  const handleSearchMasjid = (query: string) => {
    debouncedSearchMasjids(query);
  };
  const handleMasjidSelection = (masjid: Masjid) => {
    setSelectedMasjid(masjid);
    if (
      (!selectedAdmin?.masjidDetails?.[0]?.masjidId && masjid) ||
      masjid?._id !== selectedAdmin?.masjidDetails?.[0]?._id
    ) {
      setIsMasjidChanged(true);
    } else {
      setIsMasjidChanged(false);
    }
  };

  const handleSubmitMasjid = async () => {
    // Only do something if the masjid changed and it's different from the existing one
    if (
      !isMasjidChanged ||
      selectedMasjid?._id === selectedAdmin?.masjidDetails?.[0]?._id
    ) {
      setIsChangingMasjid(false);
      return;
    }

    const toastId = toast.loading("Updating Masjid...");

    try {
      // Decide whether we're assigning or unassigning the masjid
      const action = selectedMasjid ? "assign" : "unassign";
      // If unassigning, masjidId should come from the admin’s current masjid
      const masjidId =
        selectedMasjid?._id || selectedAdmin?.masjidDetails?.[0]?._id;

      const variables = {
        userId: selectedAdmin._id,
        masjidId,
        action,
      };

      const { data, errors } = await updateAdminMasjid({
        variables,
        // errorPolicy: "all",
      });

      // Check for a successful response
      if (errors && errors.length > 0) {
        throw new ApolloError({ graphQLErrors: errors });
      }

      // 2) If server signals an error in 'data'
      // For example, userMasjidAction might have success: false
      if (!data?.userMasjidAction) {
        throw new Error("No userMasjidAction was returned from the server.");
      }

      // Success handling
      toast.dismiss(toastId);
      toast.success("Masjid Updated Successfully");
      setSkipfetchAdmin(false);
      refetch();
    } catch (error) {
      // Dismiss the loading toast
      toast.dismiss(toastId);
      setIsMasjidChanged(false);
      setSelectedMasjid(null);

      // Default fallback
      let errorMessage = "Error Updating Masjid";

      // If it's an ApolloError, check for graphQLErrors
      if (error instanceof ApolloError && error.graphQLErrors.length) {
        errorMessage = error.graphQLErrors[0].message;
      }
      // Otherwise, see if it's a standard JS error
      else if (error instanceof Error) {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
      console.error(error);
    } finally {
      // Always stop the loading indicator for changing masjid
      setIsChangingMasjid(false);
    }
  };

  const handleCancelMasjid = () => {
    setIsMasjidChanged(false);
    setIsChangingMasjid(false);
    setSelectedMasjid(selectedAdmin?.masjidDetails?.[0] ?? null);
  };

  // 2. A small helper to copy text to clipboard and show a toast
  const handleCopy = (text: string | undefined) => {
    if (!text) return;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy!");
      });
  };

  return (
    <div
      style={{
        width: "100%",
        ...(isLoading ? { display: "flex", justifyContent: "center" } : {}),
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : showReasonConfirmation ? (
        <ReasonConfirmation
          onConfirm={(status: string) => {
            if (!reason) {
              setReasonError(true);
              return;
            }
            handleUpdateAdminStatus(status);
          }}
          reason={reason}
          setReason={setReason}
          reasonError={reasonError}
          setReasonError={setReasonError}
          isLoading={isLoading}
          status={
            adminStatus === AdminStatus.ACTIVE && selectedAdmin?.isApproved
              ? "freezing"
              : adminStatus === AdminStatus.DEACTIVE
              ? "approving"
              : "pending"
          }
        />
      ) : showLogs ? (
        <StatusLogs logs={logs} feature={"Masjid Assignment"} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: "auto",
          }}
        >
          <Box>
            {/* ----- Account Status Section ----- */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Typography variant="subtitle2">Account Status</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      adminStatus === AdminStatus.ACTIVE || adminStatus === null
                        ? "#00D23B"
                        : adminStatus === AdminStatus.UNVERIFIED
                        ? "#EDA920"
                        : "#FF4949",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  {getFormattedAdminStatus(adminStatus) ?? ""}
                </Typography>
              </div>
              {!(
                // Ignore freeze if these conditions:
                (
                  (selectedAdmin?.masjidDetails?.length > 0 &&
                    !selectedAdmin?.isApproved) ||
                  adminStatus === AdminStatus.DEACTIVE
                )
              ) && (
                <Button
                  variant="contained"
                  sx={{
                    width: "105px",
                    borderRadius: "8px",
                    bgcolor: "#FF2D30",
                    "&:hover": { bgcolor: "#FF2D30" },
                    textTransform: "none",
                    padding: "3px",
                    boxShadow: "none",
                    fontSize: "0.775rem",
                  }}
                  onClick={() => {
                    handleAction("freezing");
                  }}
                >
                  <Box
                    component="img"
                    src={rejectedBtnCross}
                    width={"11px"}
                    marginRight="5px"
                  />
                  Freeze
                </Button>
              )}
              {adminStatus === AdminStatus.DEACTIVE && (
                <Button
                  variant="contained"
                  sx={{
                    width: "105px",
                    borderRadius: "8px",
                    bgcolor: "#00D23B",
                    "&:hover": { bgcolor: "#00D23B" },
                    textTransform: "none",
                    padding: "3px",
                    boxShadow: "none",
                    fontSize: "0.775rem",
                  }}
                  onClick={() => {
                    handleAction("approving");
                  }}
                >
                  <Box
                    component="img"
                    src={approvedBtnTick}
                    width={"14px"}
                    marginRight="5px"
                  />
                  Unfreeze
                </Button>
              )}
            </Box>

            {/* ----- Assign Masjid Section ----- */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "60%" }}>
                <Typography variant="subtitle2" sx={{ mt: 2 }}>
                  Assign Masjid
                </Typography>
                {!isChangingMasjid && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography
                      variant="body2"
                      sx={getValueFieldStyles(
                        Array.isArray(selectedAdmin?.masjidDetails) &&
                          selectedAdmin?.masjidDetails.length > 0
                          ? selectedAdmin?.masjidDetails?.masjidName
                          : ""
                      )}
                    >
                      {/* <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      > */}
                      <span
                        style={{
                          color: !selectedAdmin?.masjidDetails[0]?.masjidName
                            ? "#FF4949"
                            : adminStatus === AdminStatus.DEACTIVE
                            ? "#A9A9A9"
                            : !selectedAdmin?.isApproved &&
                              selectedAdmin?.masjidDetails[0]?.masjidName
                            ? "#F7A400"
                            : selectedAdmin?.isApproved &&
                              selectedAdmin?.masjidDetails[0]?.masjidName
                            ? "#1B8368"
                            : "#FF4949",
                          maxWidth: "75%",
                          whiteSpace: "break-spaces",
                          overflow: "hidden",
                        }}
                      >
                        {!selectedAdmin?.isApproved &&
                        selectedAdmin?.masjidDetails[0]?.masjidName
                          ? `Pending Assignment (${selectedAdmin?.masjidDetails[0]?.masjidName})`
                          : selectedAdmin?.masjidDetails[0]?.masjidName ??
                            "Not Available"}
                      </span>
                      {/* </Box> */}
                    </Typography>
                    {selectedAdmin?.masjidDetails[0]?.masjidName && (
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleCopy(
                            selectedAdmin?.masjidDetails[0]?.masjidName
                          )
                        }
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {adminStatus !== AdminStatus.DEACTIVE &&
                  selectedAdmin?.masjidDetails?.length > 0 &&
                  !selectedAdmin?.isApproved && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "110px",
                        borderRadius: "8px",
                        bgcolor: "#00D23B",
                        "&:hover": { bgcolor: "#00D23B" },
                        textTransform: "none",
                        padding: "3px",
                        boxShadow: "none",
                        fontSize: "0.775rem",
                      }}
                      onClick={() => {
                        handleAction("pending");
                      }}
                    >
                      <Box
                        component="img"
                        src={approvedBtnTick}
                        width={"14px"}
                        marginRight="5px"
                      />
                      Assign
                    </Button>
                  )}
                {adminStatus !== AdminStatus.DEACTIVE && !isChangingMasjid && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "105px",
                      borderRadius: "8px",
                      bgcolor: "#1B8368",
                      "&:hover": { bgcolor: "#1B8368" },
                      textTransform: "none",
                      padding: "2.5px",
                    }}
                    onClick={() => {
                      setIsChangingMasjid(true);
                    }}
                  >
                    Change
                    <Box
                      component={"img"}
                      src={ChangeIcon}
                      sx={{ width: "20px", marginLeft: "5px" }}
                    />
                  </Button>
                )}
              </div>
            </Box>
            {isChangingMasjid && (
              <Box mt={2}>
                {isChangingMasjid && masjidsList && (
                  <Box mt={2}>
                    <Dropdown
                      loadingOptions={loading}
                      label={
                        Array.isArray(selectedAdmin?.masjidDetails) &&
                        selectedAdmin?.masjidDetails?.length > 0
                          ? selectedAdmin?.masjidDetails[0]?.masjidName
                          : "Select a Masjid"
                      }
                      options={masjidsList?.length > 0 ? masjidsList : []}
                      onSelect={handleMasjidSelection}
                      error={false}
                      selectedValue={
                        selectedMasjid
                          ? selectedMasjid
                          : Array.isArray(selectedAdmin?.masjidDetails) &&
                            selectedAdmin.masjidDetails.length > 0
                          ? selectedAdmin?.masjidDetails[0]._id
                          : ""
                      }
                      onInputChange={(query: string) => {
                        handleSearchMasjid(query);
                      }}
                      isMasjidChanged={isMasjidChanged}
                      handleSubmitMasjid={handleSubmitMasjid}
                      handleCancelMasjid={handleCancelMasjid}
                    ></Dropdown>
                  </Box>
                )}
              </Box>
            )}

            {/* ----- Role ----- */}
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Role
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.role)}
              >
                {roleRenamer(selectedAdmin?.role ?? "")}
              </Typography>
              {selectedAdmin?.role && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(roleRenamer(selectedAdmin?.role))}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            {/* ----- Full Name ----- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Full Name
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.name)}
              >
                {selectedAdmin?.name ?? ""}
              </Typography>
              {selectedAdmin?.name && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(selectedAdmin.name)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            {/* ----- Email Address ----- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Email Address
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.email)}
              >
                {selectedAdmin?.email ?? ""}
              </Typography>
              {selectedAdmin?.email && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(selectedAdmin.email)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>

            {/* ----- Contact Number ----- */}
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Contact Number
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="body2"
                sx={getValueFieldStyles(selectedAdmin?.contact)}
              >
                {selectedAdmin?.contact ?? "Not Available"}
              </Typography>
              {selectedAdmin?.contact && (
                <IconButton
                  size="small"
                  onClick={() => handleCopy(selectedAdmin.contact)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 2, textTransform: "capitalize" }}
                >
                  Created At
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(selectedAdmin?.createdAt)}
                  >
                    {selectedAdmin?.createdAt
                      ? moment
                          .utc(selectedAdmin.createdAt)
                          .local()
                          .format("DD MMM, YYYY | hh:mm A")
                      : "Not Available"}
                  </Typography>
                  {/* {selectedAdmin?.createdAt && (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleCopy(
                                      moment
                                        .utc(selectedAdmin.createdAt)
                                        .local()
                                        .format("DD MMM, YYYY | hh:mm A")
                                    )
                                  }
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              )} */}
                </Box>
              </Box>

              <Box sx={{ width: "50%" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mt: 2, textTransform: "capitalize" }}
                >
                  Last Updated At
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(selectedAdmin?.updatedAt)}
                  >
                    {selectedAdmin?.updatedAt
                      ? moment
                          .utc(selectedAdmin.updatedAt)
                          .local()
                          .format("DD MMM, YYYY | hh:mm A")
                      : "Not Available"}
                  </Typography>
                  {/* {selectedAdmin?.updatedAt && (
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleCopy(
                                      moment
                                        .utc(selectedAdmin.updatedAt)
                                        .local()
                                        .format("DD MMM, YYYY | hh:mm A")
                                    )
                                  }
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              )} */}
                </Box>
              </Box>
            </Box>

            {/* ----- Logs Info ----- */}
            {(adminStatus === AdminStatus.ACTIVE ||
              adminStatus === AdminStatus.DEACTIVE) &&
              logs.length > 0 && (
                <>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, textTransform: "capitalize" }}
                  >
                    {getFormattedAdminStatus(logs[0]?.status) ?? ""} By
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(logs[0]?.userDetails.name)}
                  >
                    {logs[0]?.userDetails.name ?? ""}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ mt: 2, textTransform: "capitalize" }}
                  >
                    {getFormattedAdminStatus(logs[0]?.status) ?? ""} On
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={getValueFieldStyles(logs[0]?.createdAt)}
                  >
                    {logs[0]?.createdAt
                      ? moment
                          .utc(logs[0].createdAt)
                          .local()
                          .format("DD MMM, YYYY | hh:mm A")
                      : "Not Available"}
                  </Typography>
                </>
              )}
          </Box>

          {/* ----- Check Logs Button ----- */}
          {(adminStatus === AdminStatus.ACTIVE ||
            adminStatus === AdminStatus.DEACTIVE) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "12px",
                mt: 3,
                gap: 2,
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: "#3D5347",
                  textDecoration: "underline",
                  textTransform: "none",
                  fontSize: "13.5px",
                  width: "150px",
                  fontWeight: "600",
                  "&:hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  setShowLogs(true);
                }}
              >
                Check Logs
              </Button>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

export default ExternalAdminHandler1;

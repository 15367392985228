import { Dispatch } from "redux";
import * as api from "../../../APICalls/index";
import {
  ChangeSnackbar,
  SnackbarAction,
} from "../SnackbarActions/SnackbarActions";
import { statusHandler } from "../../../helpers/HelperFunction";

type MyType = {
  body: string | undefined;
  masjidIds: string[];
  title: string | undefined;
  image: File | null;
};
export const AnnouncementNotificationUpdate = (
  announcementId: string,
  formData: MyType
) => async (dispatch: Dispatch<any>) => {
  console.log(formData, "formdata");
  try {
    if (!announcementId) throw Error("Announcement id not found");
    let { data, status } = await api.updateAnnouncement(
      announcementId,
      formData
    );

    data = { ...data, ...statusHandler(status) };

    if (data.success) {
      const snackbarDetails = {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Notification Updated",
      };
      dispatch(ChangeSnackbar(snackbarDetails));
      return data;
    }
    return data;
  } catch (error) {
    const snackbarDetails = {
      snackbarOpen: true,
      snackbarType: "error",
      snackbarMessage: "Failed to Update Notification",
    };
    dispatch(ChangeSnackbar(snackbarDetails));

    return error;
  }
};

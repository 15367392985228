import {
  Box,
  Button,
  debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "./basicDropdown/Dropdown";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import addAdminWhite from "../../../Photos/Newuiphotos/Admins/addAdminWhite.png";
import styles from "./styles.module.css";
import toast from "react-hot-toast";
import { useQuery } from "@apollo/client";
import { GET_MASJIDS } from "../../../graphql/queries";
import { Masjid, tempAdmin } from "../../../../Types";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { useSearchMasjids } from "../../../graphql/Masjids/query";

type InternalAdminFormProps = {
  open: boolean;
  onClose: () => void;
  handleSubmit: (
    name: string,
    email: string,
    role: string,
    selectedMasjid: string
  ) => void;
  isEditMode?: boolean;
  existingDetails?: tempAdmin | null;
  adminType: string;
};

const InternalAdminForm = ({
  open,
  onClose,
  handleSubmit,
  isEditMode = false,
  existingDetails = null,
  adminType,
}: InternalAdminFormProps) => {
  const [masjidsList, setMasjidsList] = useState<Masjid[]>([]);
  // const [masjids, setMasjids] = useState<Masjid[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMasjid, setSelectedMasjid] = useState("");

  const [selectedRole, setSelectedRole] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{
    name: boolean;
    email: boolean;
    selectedMasjid?: boolean;
    selectedRole: boolean;
  }>({
    name: false,
    email: false,
    selectedMasjid: false,
    selectedRole: false,
  });
  const { masjids, loading, error } = useSearchMasjids(searchQuery);

  // Update masjidList when data is fetched
  useEffect(() => {
    if (masjids && !loading && !error) {
      setMasjidsList(masjids); // Update the list with fetched masjids
    }
  }, [masjids]);

  const debouncedSearchMasjids = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [searchQuery]
  );
  const handleSearchMasjid = (query: string) => {
    debouncedSearchMasjids(query);
  };

  // };

  useEffect(() => {
    if (existingDetails && Object.keys(existingDetails).length > 0) {
      setSelectedRole(existingDetails?.role ?? "");
      setName(existingDetails?.name ?? "");
      setEmail(existingDetails?.email ?? "");
      setSelectedMasjid(existingDetails?.selectedMasjid ?? "");
    }
  }, [existingDetails]);

  useEffect(() => {
    if (!open) {
      setName("");
      setEmail("");
      setSelectedMasjid("");
      setSelectedRole("");
      setErrors({
        name: false,
        email: false,
        selectedMasjid: false,
        selectedRole: false,
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setIsSubmitting(false);
        setName("");
        setEmail("");
        setSelectedMasjid("");
        setSelectedRole("");
        setErrors({
          name: false,
          email: false,
          selectedMasjid: false,
          selectedRole: false,
        });
        setIsSubmitting(false);
        setSearchQuery("");
        onClose();
      }}
      //   sx={{
      //     "& .MuiPaper-root": {},
      //   }}
      PaperProps={{
        sx: {
          width: "60%",

          borderRadius: "22px",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          borderBottom: "1px solid #ccc",
          color: "#3D5347",
          fontSize: "16px",
          fontWeight: "600",
        }}
      >
        {isEditMode ? "Update Admin Details" : "Add New Admin Details"}
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="subtitle2" sx={{ marginTop: "12px", mb: 1 }}>
            Role
          </Typography>
          <Dropdown
            label={roleRenamer(selectedRole) || "Select a Role"}
            options={
              adminType === "internal"
                ? [
                    { label: "Select a Role", value: "" },

                    {
                      label: "Data Entry",
                      value: "admin",
                    },
                  ]
                : [
                    { label: "Select a Role", value: "" },

                    {
                      label: "Masjid Admin",
                      value: "subadmin",
                    },
                    {
                      label: "Musali Admin",
                      value: "musaliadmin",
                    },
                  ]
            }
            onSelect={(role: string) => {
              setSelectedRole(role);
              if (role === "admin" || role === "") {
                setSelectedMasjid("");
              }
              if (!isSubmitting) return;
              if (role) {
                setErrors((oldErrors) => ({
                  ...oldErrors,
                  selectedRole: false,
                }));
              } else {
                setErrors((oldErrors) => ({
                  ...oldErrors,
                  selectedRole: true,
                }));
              }
            }}
            error={errors.selectedRole}
            selectedValue={selectedRole}
            isMasjidChanged={false}
          ></Dropdown>
          {!isEditMode &&
            selectedRole !== "" &&
            selectedRole !== "admin" &&
            selectedRole !== "superadmin" && (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{ marginTop: "12px", mb: 1 }}
                >
                  Assign Masjid
                </Typography>
                {masjidsList && (
                  <SearchDropdown
                    options={masjidsList || []}
                    placeholder="Type To Search Masjid."
                    onInputChange={(query) => {
                      if (query === "") {
                        setSelectedMasjid("");
                      }

                      handleSearchMasjid(query);

                      // handleSearchMasjid?.(query);
                    }} // Call the search function
                    onSelect={(selectedOption: any) => {
                      console.log(selectedOption);
                      if (selectedOption) {
                        setSelectedMasjid(selectedOption._id);
                      }
                    }}
                    error={false}
                    selectedValue={selectedMasjid}
                    loading={loading}
                    clearData={() => {
                      setSelectedMasjid("");
                    }}
                  />
                )}
                {/* <Dropdown
                  loadingOptions={loadingMasjids}
                  label={selectedMasjid || "Select a Masjid"}
                  options={
                    dataMasjids?.getMasjids &&
                    Array.isArray(dataMasjids.getMasjids) &&
                    dataMasjids?.getMasjids.length > 0
                      ? [
                          { label: "Select a Masjid", value: "" },
                          ...dataMasjids.getMasjids
                            .slice()
                            .sort((a, b) =>
                              a.masjidName.localeCompare(b.masjidName)
                            )
                            .map((masjid: Masjid) => ({
                              label: masjid.masjidName,
                              value: masjid._id,
                            })),
                        ]
                      : [
                          { label: "Select a Masjid", value: "" },
                          // {
                          //   label: "Islamic Center of Irving",
                          //   value: "Islamic Center of Irving",
                          // },
                          // {
                          //   label: "East Plano Islamic Center",
                          //   value: "East Plano Islamic Center",
                          // },
                          // {
                          //   label: "Madina Masjid",
                          //   value: "Madina Masjid",
                          // },
                          // {
                          //   label: "ICQC Masjid",
                          //   value: "ICQC Masjid",
                          // },
                        ]
                  }
                  onSelect={(masjid: string) => {
                    setSelectedMasjid(masjid);
                    // if (!isSubmitting) return;
                    // if (masjid) {
                    //   setErrors((oldErrors) => ({
                    //     ...oldErrors,
                    //     selectedMasjid: false,
                    //   }));
                    // } else {
                    //   setErrors((oldErrors) => ({
                    //     ...oldErrors,
                    //     selectedMasjid: true,
                    //   }));
                    // }
                  }}
                  error={errors.selectedMasjid}
                  selectedValue={selectedMasjid}
                  isMasjidChanged={false}
                ></Dropdown> */}
              </>
            )}

          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "12px" }}
          >
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Name*
            </Typography>
            <input
              type="text"
              name="fullName"
              placeholder={"Name*"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (!isSubmitting) return;
                console.log();
                if (e.target.value && /^[a-zA-Z ]+$/.test(e.target.value)) {
                  setErrors((oldErrors) => ({ ...oldErrors, name: false }));
                } else {
                  setErrors((oldErrors) => ({ ...oldErrors, name: true }));
                }
              }}
              style={{
                padding: "0 15px",
                border: "1px solid #ccc",
                borderRadius: "25px",
                // font-size: .8rem;
                height: "45px",
              }}
              className={`${errors.name ? styles.inputError : ""}`}
            />
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", marginTop: "12px" }}
          >
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Email Address*
            </Typography>
            <input
              type="text"
              name="fullName"
              placeholder={"Email Address*"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (!isSubmitting) return;

                const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                if (e.target.value && re.test(e.target.value)) {
                  setErrors((oldErrors) => ({ ...oldErrors, email: false }));
                } else {
                  setErrors((oldErrors) => ({ ...oldErrors, email: true }));
                }
              }}
              style={{
                padding: "0 15px",
                border: "1px solid #ccc",
                borderRadius: "25px",
                // font-size: .8rem;
                height: "45px",
              }}
              className={`${errors.email ? styles.inputError : ""}`}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            margin: "25px",
            gap: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "50%",
              borderRadius: "20px",
              bgcolor: "#A9A9A9",
              "&:hover": { bgcolor: "#A9A9A9" },
              textTransform: "none",
              padding: "5px",
            }}
            onClick={() => {
              setIsSubmitting(false);
              setErrors({
                name: false,
                email: false,
                selectedMasjid: false,
                selectedRole: false,
              });
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              width: "50%",
              borderRadius: "20px",
              textTransform: "none",
              //   textWrap: "nowrap",
              padding: "5px",
              bgcolor: "#1B8368",
              ":hover": {
                borderWidth: "2px",
                bgcolor: "#1B8368",
              },
            }}
            onClick={() => {
              setIsSubmitting(true);
              const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              const newErrors = {
                // selectedMasjid:
                //   selectedRole !== "admin" && !selectedMasjid ? true : false,
                name: !name || !/^[a-zA-Z ]+$/.test(name) ? true : false,
                email: !email || !re.test(email) ? true : false,
                selectedRole: !selectedRole ? true : false,
              };
              setErrors(newErrors);

              console.log(Object.values(newErrors));
              if (!Object.values(newErrors).includes(true)) {
                // console.log("all good");
                handleSubmit(name, email, selectedRole, selectedMasjid);
              } else {
                if (
                  !/^[a-zA-Z ]+$/.test(name)
                  // && Object.values(newErrors).filter((error) => error === true)
                  //   .length === 1
                ) {
                  toast.error(
                    "Incorrect name format. Must not contain digits and special characters"
                  );
                } else if (
                  email != "" &&
                  !re.test(email)
                  // && Object.values(newErrors).filter((error) => error === true)
                  //   .length === 1
                ) {
                  toast.error("Incorrect email format");
                } else {
                  toast.error("Please fill in all the required details");
                }
              }
            }}
          >
            <Box
              component="img"
              src={addAdminWhite}
              sx={{ width: "20px", marginRight: "8px" }}
            />
            {isEditMode ? "Update Admin" : "Add new Admin"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InternalAdminForm;

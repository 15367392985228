import React, { useMemo } from "react";
import { roleRenamer } from "../../../helpers/RoleRenamer";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getAdminStatus, getFormattedAdminStatus } from "../helpers";
import { Box, IconButton, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {
  getFormattedMasjidStatus,
  getStatus,
} from "../../PageComponents/MasjidTable/Helper";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { AdminInterFace, Masjid } from "../../../../Types";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .no-rows-primary": {
    fill: "#3D4751",
    ...theme.applyStyles("light", {
      fill: "#AEB8C2",
    }),
  },
  "& .no-rows-secondary": {
    fill: "#1D2126",
    ...theme.applyStyles("light", {
      fill: "#E8EAED",
    }),
  },
}));
function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2 }}>No rows</Box>
    </StyledGridOverlay>
  );
}
interface ExternalTableProps {
  loading: boolean;
  filteredData: AdminInterFace[] | [];
  handleActionClick: (action: any, details: AdminInterFace) => void;
  handleActionClickForMasjid: (action: string, details: Masjid) => void;
}
const ExternalTable = ({
  loading,
  filteredData,
  handleActionClick,
  handleActionClickForMasjid,
}: ExternalTableProps) => {
  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", flex: 1 },
      { field: "email", headerName: "Email", flex: 1 },
      { field: "contact", headerName: "Contact", flex: 1 },
      {
        field: "role",
        headerName: "Role",
        flex: 1,
        valueGetter: (params: any) => {
          const value = params.row.role;
          return roleRenamer(value);
        },
      },
      {
        field: "isAddedFromAdmin",
        headerName: "isAddedFromAdmin",
        flex: 1,
        valueGetter: (params: any) => {
          const value = params.row.isAddedFromAdmin;
          if (value) {
            return "Yes";
          } else {
            return "No";
          }
        },
      },
      {
        field: "status",
        headerName: "Account Status",
        // flex: 1,
        width: 100,
        valueGetter: (params: any) => {
          const value = getFormattedAdminStatus(
            getAdminStatus(params.row.isFreezed, params.row.isVerified)
          );
          return value;
        },
        renderCell: (params: any) => {
          let color;
          const value = getAdminStatus(
            params.row.isFreezed,
            params.row.isVerified
          );
          // Check the role and set the color accordingly
          if (value === "approved" || value === null) {
            color = "#1B8368";
          } else if (value === "pending") {
            color = "#EDA920";
          } else {
            color = "#FF4949";
          }
          return (
            <Typography
              style={{
                color,
                cursor: "pointer",
                textTransform: "capitalize",
              }}
              variant="body2"
              onClick={() => {
                handleActionClick(value, params.row);
              }}
            >
              {getFormattedAdminStatus(
                getAdminStatus(params.row.isFreezed, params.row.isVerified)
              )}
            </Typography>
          );
        },
      },
      {
        field: "masjid",
        headerName: "Masjid Assignment",
        // flex: 1,
        width: 200,
        valueGetter: (params: any) => {
          return !params.row?.masjidDetails[0]?.masjidName
            ? ""
            : !params.row?.isApproved
            ? `Pending (${params.row?.masjidDetails[0]?.masjidName})`
            : params.row?.masjidDetails[0]?.masjidName;
        },
        renderCell: (params: any) => {
          let color;
          const value = getAdminStatus(
            params.row.isFreezed,
            params.row.isVerified
          );

          // Check the role and set the color accordingly
          if (value === "rejected" || value === null) {
            color = "#A9A9A9";
          } else if (!params.row.masjidDetails[0]?.isAssigned) {
            color = "#EDA920";
          } else {
            color = "#1B8368";
          }
          return (
            <Typography
              style={{
                color:
                  !params.row?.isApproved && !params.row?.isAddedFromAdmin
                    ? "#F7A400"
                    : "#1B8368",
                cursor: "pointer",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              variant="body2"
            >
              {!params.row?.masjidDetails[0]?.masjidName ? (
                ""
              ) : !params.row?.isApproved && !params.row?.isAddedFromAdmin ? (
                <Link
                  style={{ color: "inherit" }}
                  to={"/masjid/" + params.row?.masjidDetails[0]?._id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pending ({params.row?.masjidDetails[0]?.masjidName})
                </Link>
              ) : (
                <Link
                  style={{ color: "inherit" }}
                  to={"/masjid/" + params.row?.masjidDetails[0]?._id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {params.row?.masjidDetails[0]?.masjidName}
                </Link>
              )}
            </Typography>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        // width: 100,
        flex: 0,
        sortable: false,
        renderCell: (params: any) => {
          const value = getAdminStatus(
            params.row.isFreezed,
            params.row.isVerified
          );
          return (
            <IconButton
              onClick={() => {
                handleActionClick(value, params.row);
              }}
            >
              <EditIcon />
            </IconButton>
          );
        },
      },
      {
        field: "masjidstatus",
        headerName: "Masjid Verification Status",
        // flex: 1,
        width: 100,
        valueGetter: (params: any) => {
          const value = getStatus(
            params.row?.masjidDetails[0]?.isFreezed,
            params.row?.masjidDetails[0]?.isVerified
          );
          return getFormattedMasjidStatus(value);
        },
        renderCell: (params: any) => {
          let color;
          const value = getStatus(
            params.row?.masjidDetails[0]?.isFreezed,
            params.row?.masjidDetails[0]?.isVerified
          );
          // Check the role and set the color accordingly
          if (value === "approved" || value === null) {
            color = "#1B8368";
          } else if (value === "pending") {
            color = "#EDA920";
          } else {
            color = "#FF4949";
          }
          return params.row?.masjidDetails?.[0] ? (
            <Typography
              style={{
                color,
                cursor: "pointer",
                textTransform: "capitalize",
              }}
              variant="body2"
              onClick={() => {
                handleActionClickForMasjid(value, params.row?.masjidDetails[0]);
              }}
            >
              {getFormattedMasjidStatus(value)}
            </Typography>
          ) : null;
        },
      },
      {
        field: "masjidaddress",
        headerName: "Masjid Address",
        flex: 1,
        hideable: true,
        // hide: true,
        valueGetter: (params: any) => {
          const value = params.row?.masjidDetails[0]?.address ?? "";
          return value;
        },
      },
      {
        field: "createdAt",
        headerName: "Created At",
        flex: 1,

        // width: 150,
        hideable: true,
        hide: true,
        valueGetter: (params: any) => {
          const value = params.row.createdAt;
          return moment.utc(value).local().format("DD-MMM-YYYY HH:mm A");
        },
      },
    ],
    [handleActionClick]
  );
  return (
    <DataGrid
      loading={loading}
      rows={filteredData}
      columns={columns}
      pageSize={20}
      rowsPerPageOptions={[20]}
      components={{
        Toolbar: GridToolbar,
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      disableSelectionOnClick
      getRowId={(row) => row._id}
      // getRowClassName={params =>
      //   params.indexRelativeToCurrentPage % 2 === 0
      //     ? "even-row"
      //     : "odd-row"}
      getRowClassName={(params) => {
        return getAdminStatus(params.row?.isFreezed, params.row?.isVerified) ===
          "rejected"
          ? "frozen-masjid-row"
          : "";
      }}
      sx={{
        "& .MuiDataGrid-virtualScroller": {
          // Always show vertical scrollbar
          // overflowY: "auto",
          // overflowX: "",
          // WebKit-based styling for the scrollbar track, thumb, etc.
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#bfbfbf",
            borderRadius: "8px",
          },
          "&:hover::-webkit-scrollbar-thumb": {
            backgroundColor: "#8f8f8f",
          },
        },

        // Optionally style horizontal scroll (for many columns):
        // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar:horizontal": {
        //   height: "8px",
        // },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-cell": {
          userSelect: "text",
          "&:hover": {
            outline: "none",
          },
        },
      }}
    />
  );
};

export default ExternalTable;

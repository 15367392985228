import React from "react";
import "./DataBox.css";
import { CircularProgress } from "@mui/material";
import { Masjid } from "../../../../Types";

interface DataBoxProps {
  data?: Masjid[];
  name: string;
  isLoading: boolean;
  length?: number;
}

const DataBox: React.FC<DataBoxProps> = ({
  data,
  name,
  isLoading,
  length = 0,
}) => {
  return (
    <>
      <div className="DataBoxContainer">
        <div className="NumberDetails page-top-title">
          {isLoading ? (
            <CircularProgress />
          ) : data ? (
            data?.length
          ) : length ? (
            length
          ) : (
            0
          )}
        </div>
        <div className="TitleDetails">{isLoading ? <></> : name}</div>
      </div>
    </>
  );
};

export default DataBox;

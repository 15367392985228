import { Dispatch } from "redux";
import * as api from "../../../APICalls/index";
import {
  ChangeSnackbar,
  SnackbarAction,
} from "../SnackbarActions/SnackbarActions";
import { statusHandler } from "../../../helpers/HelperFunction";

type OTPType = {
  otp: string;
};
export const AnnouncementOTPVerifierAction = (formData: OTPType) => async (
  dispatch: Dispatch<any>
) => {
  try {
    let { data, status } = await api.triggeringAnnouncementOTPVerifier(
      formData
    );
    data = { ...data, ...statusHandler(status) };

    if (data.success) {
      const snackbarDetails = {
        snackbarOpen: true,
        snackbarType: "success",
        snackbarMessage: "Sent the Announcement to all the Masjid",
      };
      dispatch(ChangeSnackbar(snackbarDetails));
      return data;
    }
    return data;
  } catch (error) {
    const snackbarDetails = {
      snackbarOpen: true,
      snackbarType: "error",
      snackbarMessage: "Failed to verify Otp",
    };
    dispatch(ChangeSnackbar(snackbarDetails));

    return error;
  }
};

import React from "react";
import { Typography, Box, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // 1) Import the copy icon
import moment from "moment";
import toast from "react-hot-toast"; // 2) For optional toast notifications

const DetailFields = ({ requestDetails, status }: any) => {
  // 3) A small helper to copy text to clipboard and show a toast
  const handleCopy = (text: string | undefined) => {
    if (!text) return;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy!");
      });
  };

  const renderCopyText = (text: string | undefined) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography
          variant="body2"
          sx={{ color: "#1B8368", fontWeight: "bold" }}
        >
          {text ?? ""}
        </Typography>
        {text && (
          <IconButton size="small" onClick={() => handleCopy(text)}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };

  return (
    <>
      {/* Status */}
      {status !== "pending" && status !== "inreview" ? (
        <>
          <Typography variant="subtitle2">Account Status</Typography>
          <Typography
            variant="body2"
            sx={{
              color: status === "approved" ? "#00c986" : "#fc4a4a",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            <div
              style={{
                marginTop: "14px",
                display: "inline-block",
                marginRight: "10px",
              }}
              className={
                status === "approved" ? "green-dot-veri" : "red-dot-veri"
              }
            ></div>
            {requestDetails?.status ?? ""}
          </Typography>
        </>
      ) : null}

      {/* Business Name */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Business Name
      </Typography>
      {renderCopyText(requestDetails?.stripeDetails?.businessName)}

      {/* Admin Email */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Admin Email Address
      </Typography>
      {renderCopyText(requestDetails?.adminEmail)}

      {/* Stripe Email */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Stripe Email Address
      </Typography>
      {renderCopyText(requestDetails?.stripeDetails?.email)}

      {/* Contact Number */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Contact Number
      </Typography>
      {renderCopyText(requestDetails?.stripeDetails?.contact)}

      {/* Admin Name */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Admin Name
      </Typography>
      {renderCopyText(requestDetails?.adminName)}

      {/* Masjid Name */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Masjid Name
      </Typography>
      {renderCopyText(requestDetails?.masjidName)}

      {/* Stripe Account Registered with */}
      <Typography variant="subtitle2" sx={{ mt: 2 }}>
        Stripe Account Registered with
      </Typography>
      {renderCopyText(requestDetails?.stripeDetails?.accountOwnerName)}

      {/* If status is approved/rejected, show who updated it and when */}
      {(status === "approved" || status === "rejected") && (
        <>
          <Typography
            variant="subtitle2"
            sx={{ mt: 2, textTransform: "capitalize" }}
          >
            {status} By
          </Typography>
          {renderCopyText(requestDetails?.lastLedger?.updatedByUser)}

          <Typography
            variant="subtitle2"
            sx={{ mt: 2, textTransform: "capitalize" }}
          >
            {status} On
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              variant="body2"
              sx={{ color: "#1B8368", fontWeight: "bold" }}
            >
              {moment
                .utc(requestDetails?.lastLedger?.createdAt)
                .local()
                .format("DD MMM, YYYY | hh:mm A")}
            </Typography>
            {requestDetails?.lastLedger?.createdAt && (
              <IconButton
                size="small"
                onClick={() =>
                  handleCopy(
                    moment
                      .utc(requestDetails?.lastLedger?.createdAt)
                      .local()
                      .format("DD MMM, YYYY | hh:mm A")
                  )
                }
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default DetailFields;
